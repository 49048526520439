import {POST} from '@/assets/js/ajax';
const requestUrl = process.env.VUE_APP_INTERFACE_ADDR;
// const requestUrl ='http://ac.emakerzone.com/v2/1v1/customer/';  // 测试
const mainUrl = process.env.VUE_APP_INTERFACE_MAIN;
const API = {
	// 获取appid
	getLogin(data:any){
		return POST(requestUrl+'wechatlogin',data)
	},
	// 检查是否是客服--获取用户信息
	getUserPlatformInfo(data:any){
		return POST(requestUrl+'checkuser',data)
	},
	// 获取im token
	getImToken(data:any){
		return POST(requestUrl+'rctoken',data)
	},
	//获取用户信息
	getUserDetail(data:any){
		return POST(requestUrl+'getuserdetail',data)
	},
	// 换取手机号码
	getUserPhone(data:any){
		return POST(requestUrl+'getwechatmobile',data)
	},
	// 客服注册
	telregister(data:any){
		return POST(requestUrl+'telregister',data)
	},
	//踢人出群
	delUserGroup(data:any){
		return POST(requestUrl+'quitgroup',data)
	},
	//加入群聊
	joinUserGroup(data:any){
		return POST(requestUrl+'joingroup',data)
	},
	//加入群聊
	joinUserGroupCs(data:any){
		return POST(requestUrl+'joingroupcs',data)
	},
	//解散群组
	removeUserGroup(data:any){
		return POST(requestUrl+'dismissgroup',data)
	},
	//群成员查询
	getGroupUser(data:any){
		return POST(requestUrl+'querygroupmember',data)
	},
	// 聊天列表
	getMsgList(data:any){
		return POST(requestUrl+'messageslist',data)
	},
	// 聊天列表
	getMsgListAll(data:any){
		return POST(requestUrl+'messageslistall',data)
	},
	//查询群组
	getQueryjoingroup(data:any){
		return POST(requestUrl+'queryjoingroup',data)
	},
	// 修改群聊状态
	updateGroupState(data:any){
		return POST(requestUrl+'mgroupstate',data)
	},
	// 通讯录
	getMailList(data:any){
		return POST(requestUrl+'addrbooklist',data)
	},

	// 创建私聊
	addUserAlone(data:any){
		return POST(requestUrl+'createcsgroup',data)
	},
	// 查询私聊
	queryUserAlone(data:any){
		return POST(requestUrl+'checkcsgroup',data)
	},
	// 转接
	transferChat(data:any){
		return POST(requestUrl+'transfermessagesout',data)
	},
	// 接受或拒绝转接
	tapTransferChat(data:any){
		return POST(requestUrl+'transfermessagesin',data)
	},
	// 查询消息接受状态
	getMsgState(data:any){
		return POST(requestUrl+'invitationmessages',data)
	},
	// 查询历史消息
	getMsgContent(data:any){
		return POST(requestUrl+'messagescontent',data)
	},
	//查询用户状态
	getMineType(data:any){
		return POST(requestUrl+'mypage',data)
	},
	//更改用户状态
	changeMineType(data:any){
		return POST(requestUrl+'modifyuseronline',data)
	},
	//转发
	relayMessage(data:any){
		return POST(requestUrl+'forwardmessages',data)
	},
	// 查看信息
	seeMessageRecord(data:any){
		return POST(requestUrl+'mergemessages',data)
	},
	//更改用户信息
	modifyUser(data:any){
		return POST(requestUrl+'modifyuser',data)
	},
	// 接收系统消息
	getSendMsg(data:any){
		return POST(requestUrl+'sendnoticemsg',data)
	},
	// 修改用户消息
	updateGroupUser(data:any){
		return POST('updategroupuser',data)
	},
	// /customer/createreward 添加分享
	createReward(data:any){
		return POST('createreward',data)
	},
	// //customer/rewardlist 分享列表展示
	fetchRewardList(data: {
		"group_id": string,
		"user_id": number
	}){
		return POST('rewardlist',data)
	},
	// /wechat/create-wxaqrcode
	fetchWxQrcode(data: {
		"appid": string,
		"name" ?: string,
		"path": string,
		"secret": string,
	}){
		if (process.env.VUE_APP_NODE === "production"){
			return POST(mainUrl+'/wechat/create-wxaqrcode',data)
		}
		return POST(mainUrl+'/platform/wechat/create-wxaqrcode',data)
	},
	// 发送评分邀请
	sendGroupComment(data:any){
		return POST('groupcomment',data)
	},
	// 消息撤回
	recallMsg(data: {
		msgUid: string
		extra: string
	}){
		return POST(requestUrl+'recall',data)
	},
	// 问题类型
	mapSettings(data: {}){
		return POST(requestUrl+'mapsetting',data)
	},

	// 延时关闭会话
	delayedSession(data: {
		group_id: string,
		status: 1|2 // 状态 1 开启延时；2 关闭延时；
	}){
		return POST(requestUrl+'delayquit',data)
	},
	messageslistgroup(data: {
		userId: string,
		m_id: number
	}){
		return POST(requestUrl+'messageslistgroup',data)
	},

	// 厂商列 /customer/manufacturerlist
	manufacturerList(data: {
		"userId": string,
	}){
		return POST('manufacturerlist',data)
	},
	// 获取打开小程序链接
	generateUrlLink(data:any){
		return POST('generateurllink',data)
	},

	// 搜索 /customer/messagessearch
	messageSearch(data: {
		"pageNum": number,
		"pageSize": number,
		"search": string,
		"type": number, //  2：会话 : 1:联系人
		"userId": string,
		is_all: number, // 0：当前；1：所有
		group_status: number, // 0：当前；1：所有, 2所有
	}){
		return POST('messagessearch',data)
	},
	//聊天历史
	messageshistory(data:any){
		return POST('messageshistory',data)
	},
}
export {API}

