import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_layoutNav = _resolveComponent("layoutNav")!
  const _component_layoutBody = _resolveComponent("layoutBody")!
  const _component_a_layout = _resolveComponent("a-layout")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$route.meta.keepAliveNav)
      ? (_openBlock(), _createBlock(_component_a_layout, {
          key: 0,
          class: "adminBody"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_layoutNav),
            _createVNode(_component_layoutBody)
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
  ]))
}