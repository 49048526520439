
import {
  ClockCircleOutlined,
  HistoryOutlined,
  CloseOutlined,
  FormOutlined,
  LoadingOutlined,
  RotateLeftOutlined,
  ShareAltOutlined,
  StopOutlined,
  UserAddOutlined,
  BellFilled,
  PlusOutlined
} from "@ant-design/icons-vue";
import {useDebounceFn} from '@vueuse/core'
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import {computed, defineComponent, nextTick, onMounted, reactive, ref, toRaw, unref, watch, inject} from "vue";
import {useRequest} from "vue-request";
import useDeleteEmoji from '@/hooks/useDeleteEmoji'

import {
  Avatar,
  Button, Card, Checkbox, Col, Collapse,
  Dropdown, Form, Input,
  Layout, List,
  Menu,
  message,
  Modal,
  Popconfirm, Radio,
  Row, Select,
  Spin, Switch,
  Tabs, Tag,
  Tooltip,
  Upload
} from "ant-design-vue";
import {ValidateErrorEntity} from "ant-design-vue/es/form/interface";


import * as RongIMLib from "@rongcloud/imlib-v4";
import {API} from "@/assets/js/Api";
import {im} from '@/utils/actions/rongim'
import {timestampToTime} from '@/utils/dateformat'
const OSS = require("ali-oss");
import Store from '@/utils/storage'
import {arraySum, minutesSeconds, sleep, specialSymbol} from "@/utils";

import EmojiJson from "../../assets/js/emoji";
import ChatGroup from './components/chatGroup'
import Share from './components/share'
import Emitter from '@/utils/mitt'
import MTooltip from '@/components/tooltip/index';
import EditTxt from '@/components/editTxt'
import SearchDrawer from './components/search'
import DragUpload from './components/dragUpload'

const mLocalStorage = new Store('localStorage')
const getTokenData = (params: {}) => {
  return API.getImToken(params);
};
// 获取用户所在群
const getUserGroup = (params: {}) => {
  return API.getMsgList(params);
};
// 超级管理员获取所有群
const getUserAllGroup = (params: {}) => {
  return API.getMsgListAll(params);
};
let originalQuestionTypes: any = []

interface MsgContent {
  content?: string;
  imageUri?: string;
  w?: number;
  h?: number;
  length?: string;
  name?: string;
  type?: string;
  fileUrl?: string;
  size?: number;
  duration?: number;
  remoteUrl?: string;
}
interface Members {
  userAvatar: string;
  userName: string;
}
interface MsgInfo {
  members: Members;
  content: MsgContent;
}
type MsgList = {
  type: string;
  content: MsgInfo;
};
type MsgAddItem = {
  type: string;
  content: MsgContent;
  imgUploadURL?: any;
  fileUploadItem?: any;
};
type msgPage = {
  pageNum: number;
  pageSize: number;
  hasMore: boolean;
};
interface FormState {
  // name: string;
  region: number | undefined;
  is_visit: boolean;
  question_type: number;
  level: boolean;

  [props: string]: any
}

export default defineComponent({
  name: "conversation",
  components: {
    HistoryOutlined,
    DragUpload,
    PlusOutlined,
    Upload,
    BellFilled,
    ClockCircleOutlined,
    StopOutlined,
    FormOutlined,
    RotateLeftOutlined,
    ShareAltOutlined,
    UserAddOutlined,
    CloseOutlined,
    LoadingOutlined,
    ChatGroup,
    Share,
    MTooltip,
    EditTxt,
    SearchDrawer,
    [Upload.name]: Upload,
    [Layout.name]: Layout,
    [Layout.Header.name]: Layout.Header,
    [Layout.Sider.name]: Layout.Sider,
    [Layout.Content.name]: Layout.Content,
    [Button.name]: Button,
    [Dropdown.name]: Dropdown,
    [Menu.name]: Menu,
    [Menu.Item.name]: Menu.Item,
    [Avatar.name]: Avatar,
    [Tabs.name]: Tabs,
    [Tabs.TabPane.name]: Tabs.TabPane,
    [Popconfirm.name]: Popconfirm,
    [Spin.name]: Spin,
    [Tooltip.name]: Tooltip,
    [Row.name]: Row,
    [Col.name]: Col,
    [Tag.name]: Tag,
    [Form.name]: Form,
    [Form.Item.name]: Form.Item,
    [Input.name]: Input,
    [Input.Search.name]: Input.Search,
    [Input.TextArea.name]: Input.TextArea,
    [Card.name]: Card,
    [Switch.name]: Switch,
    [Select.name]: Select,
    [Select.Option.name]: Select.Option,
    [Modal.name]: Modal,
    [Radio.name]: Radio,
    [Radio.Group.name]: Radio.Group,
    [Collapse.name]: Collapse,
    [Collapse.Panel.name]: Collapse.Panel,
    [List.name]: List,
    [List.Item.name]: List.Item,
    'a-list-item-meta': List.Item.Meta,
    [Checkbox.name]: Checkbox,
    [Checkbox.Group.name]: Checkbox.Group,
  },
  setup(props, ctx) {
    const route = useRoute();
    const router = useRouter();
    const unionid = JSON.parse(localStorage.getItem("unionid") as string);
    const onShow = ref<boolean>(true);

    const chatBoxRef = ref()
    const chatBoxHeight = ref<number>(0);
    const mid = ref<number>(-1)
    /** 筛选厂商会话*/
    watch(() => route.query, (v) => {
      mid.value = +(v?.mid ?? -1)
      showSearch.value = false
      switch (activeTab.value){
        case 'current':
          page.value.pageNum = 1
          page.value.hasMore = false
          runGroupList({userId: userPlatformInfo.value.userId.toString()});
          break
        case 'end':
        case 'all':
          allGroupParams.pageNum = 1
          allGroupParams.group_state = activeTab.value === 'end' ? 2 : 0
          runAllGroupListCore({
            ...allGroupParams,
            m_id : +mid.value,
            type: 0,
            userId: userPlatformInfo.value.userId.toString()
          })
          break
      }
    })

    const mapSettings = () => {
      API.mapSettings({}).then((res: any) => {
        originalQuestionTypes = res.data
      })
    }

    onMounted(() => {
      getAllConversations()
      if (unionid) {
        getUserPlatformInfo(unionid)
      }
      nextTick(() => {
        chatBoxHeight.value = chatBoxRef.value.scrollHeight
      })
      mapSettings()

    })

    /**
     * 获取用户信息
     */
    const getUserPlatformInfo = (id: any) => {
      let data = {
        IsCustomerClient: 0,
        unionId: id,
        channelType: 1, //属于哪个渠道：1 pc；2 小程序
      };
      API.getUserPlatformInfo(data).then((res: any) => {
        let json = res.data;
        userPlatformInfo.value = json
        sessionStorage.setItem("userPlatformInfo", JSON.stringify(json));
        if (json.im_user_type == 1) {
          Modal.error({
            title: "温馨提示",
            content: "该账号不是客服！",
            okType: "danger",
            okText: "确认",
            onOk() {
            },
          });
        } else {
          timingFun()
        }
      });
    };

    const showConsult = ref<boolean>(false);
    const questionTypeValues = ref<any>([])

    const closeShowConsult = () => {
      showConsult.value = false;
      localStorage.setItem("showConsult", "false");
      page.value.pageNum = 1;
      page.value.hasMore = false;
      msgList.value = [];
    };
    const imToken = ref<string>("");
    const userPlatformInfo = ref<any>(JSON.parse(sessionStorage.getItem("userPlatformInfo") as string) || null);

    // 机器人token
    const imRobotToken = ref<string>('')
    // 获取token
    const {data: imTokenData} = useRequest(getTokenData, {
      defaultParams: [
        {
          name: userPlatformInfo.value.realname,
          portraitUri: userPlatformInfo.value.avatar,
          userId: userPlatformInfo.value.userId.toString(),
        },
      ],
      formatResult: (res: any) => {
        // 设置机器人token
        imRobotToken.value = res.data.robot_token
        return res.data.token
      },
      onSuccess: (res: any) => {
        getImLink(res);
      },
    });
    const chatroomItem = ref<any>({});
    const chatroomId = ref<string>("");
    const serviceActiveKey = ref([])
    watch(chatroomId, (val: string) => {
      serviceDataList.value = []
      if (!val) {
        GroupName.value = '';
      }
    })
    const isBrowseCurrentMsg = ref<number>(1); //是否只查看当前消息（0 否；1 是；）
    const showChatroom = ref<boolean>(true);
    let allCurrent = ref<any[]>([])
    const getAllConversations = () => {
      if (+(route.query.mid ?? -1) > -1 ){
        return new Promise((resolve, reject) => {
          API.messageslistgroup({
            userId: ''+userPlatformInfo.value.userId,
            m_id: -1
          }).then((res:any)=>{
            allCurrent.value = res.data.list || []
          })
        })
      }
    }


    // 获取当前会话列表
    const runGroupList = useDebounceFn((params: any = {}) => {
      getAllConversations()

      return runGroupListCore({...params, m_id : +mid.value ?? +(route.query.mid ?? -1) })
    }, 100)
    const {run: runGroupListCore, data: groupList, loading} = useRequest(getUserGroup, {
      manual: true,
      formatResult: (res: any) => {
        const list = res.data.list.length > 0 ? res.data.list.map((item: any, index: number) => {
          let itemJson = {...item};
          let conversation = im.Conversation.get({
            targetId: itemJson.groupId,
            type: RongIMLib.CONVERSATION_TYPE.GROUP,
          });
          conversation.getUnreadCount().then((count: any) => {
            itemJson.noReadNum = count;
            if (res.data.list.length - 1 === index) {
              (groupList as any).value.push([]);
              (groupList as any).value.pop();
            }
          });
          return itemJson;
        }) : [];
        return list;
      },
      onSuccess: () => {
        timingFun();
        renderByGID()
        if (changeList.value) {
          return false;
        }
        showChatroom.value = true;
      },
    });

    const effect = ref(0)
    // 查询用户所在群
    const unreadCount = computed(  () => {
      let list = (+(route.query.mid ?? -1) > -1) ? allCurrent.value : groupList.value
      console.log(allCurrent.value);
      return {list, effect:effect.value}
    });

    watch(unreadCount, async () => {
      if (imStatus.value !== 0) return
      let unreads = 0
      for (const {groupId} of (unreadCount.value.list || [])) {
        unreads += +(await getUnreadCountByGroup(groupId))
      }
      Emitter.emit('unreadCount', unreads)
    })

    /************** 超级管理获取所有群 **********************/
    const allGroupParams = reactive({
      pageNum: 1,
      pageSize: 10,
      search: '',
      group_state: 1,
    })
    const groupAllLists = ref<any []>([])
    const loadAllMore = ref<boolean>(false)

    const {run: runAllGroupListCore, data: groupAllList} = useRequest(getUserAllGroup, {
      manual: true,
      defaultParams: [
        {
          ...allGroupParams,
          userId: userPlatformInfo.value.userId.toString(),
        },
      ],
      onSuccess: (res: any) => {
        // console.log("查询用户所在群", res);
      },
    });
    const loadMoreAllGroup = async () => {
      allGroupParams.pageNum++;
      getUserAllGroupCore(false)
    }

    // 获取所有会话数据
    const getUserAllGroupCore = async (initial = true): Promise<void> => {
      if (initial) {
        groupAllLists.value = []
      }

      await runAllGroupListCore({
        m_id : +mid.value,
        ...allGroupParams,
        userId: userPlatformInfo.value.userId.toString(),
        search: servicName.value,
        type: 0,
      })
      const {list = [], page: {total}} = (groupAllList.value as any).data;
      const {pageNum, pageSize} = allGroupParams;

      loadAllMore.value = pageNum * pageSize < total

      if (initial) {
        groupAllLists.value = list
      } else {
        groupAllLists.value = groupAllLists.value.concat(list)
      }
    }


    // 显示权限显示框
    const showSelectRule = ref<boolean>(false)
    const selectGroup = ref<any>({
      title: '选择',
      showSelectRule: false,
      joinInitiated: 'join',
      groupInfo: []
    })
    let switchChat = false
    // 点击进入会话
    const groupAllListItem = (item: any) => {
      switchChat = true;
      // 存在群里直接进入
      if (item.in_group === 1) {
        groupListItem(item);
        // 直接进入
        return;
      }
      const {state, second_name, key_word_delete} = item;
      const title = second_name //item.name + (item.chat_type !== 2 ? (item.second_name ?`【${item.second_name}】`:''): '');

      selectGroup.value.title = `${title}`;
      // 自己的群聊直接进入（已结束） 显示选择框 发起主动会话和 查看聊天
      if (state !== 1) { //结束
        selectGroup.value.joinInitiated = `initiate`; // 发起主动会话
      } else {
        selectGroup.value.joinInitiated = `join`; // 加入群聊
      }
      if (key_word_delete === 0) {
        selectGroup.value.joinInitiated = `deleted`; // 已删除
      }
      selectGroup.value.groupInfo = item;

      //TODO 判断是否为普通管理员 普通管理员只能查看 1 普通管理 2客服 3超级管理
      // const itemList = ['查看聊天记录'];
      // if (this.userPlatformInfo.value.is_manage === 3){
      //
      // }
      //显示选择框，聊天记录/加入会话
      //selectGroup.value.showSelectRule = true;
      //进入聊天记录
      chooseOption(2)
    }

    const selectRobot = ref<boolean>(false)
    // 选择选项
    const chooseOption = (type: number) => {
      if (type === 1) { // 加入群聊
        addUserToGroup(1)
      } else { // 查看群聊
        // 切换客服查看没有权限的群聊
        toggleChatAccount(imRobotToken.value).then(res => {
          message.warning('查看聊天会导致收不到当前进行会话列表新消息， 请勿一直停留！', 5)
          // 切换聊天
          groupListItem(selectGroup.value.groupInfo, true);
          selectGroup.value.showSelectRule = false;
          selectRobot.value = true;
        }).catch(err => {
          // message.error(err);
        });

      }
    }

    const repeatClick = ref<boolean>(false);

    /**
     * 类型（1：加入会话；2：发起会话；）
     * @param type
     */
    const addUserToGroup = (type: number): void => {
      const title = type === 1 ? '加入群聊' : '主动会话';
      const content = type === 1 ? '介入会话后，您将成为新的群主' : '介入会话后，您将成为新的群主';

      Modal.confirm({
        title,
        content,
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          if (repeatClick.value) return;
          repeatClick.value = true;
          const {groupId} = selectGroup.value.groupInfo;
          fetchAddUserGroup(groupId, 2).then((res: any) => {
            message.success('处理成功');
            groupListItem(selectGroup.value.groupInfo, false);
            selectGroup.value.showSelectRule = false;
            chatroomItem.value.memberType = 2
            // 刷新所有会话
            getExistedConversationList(null)
            // 切换tab
            changeActiveTab('current')
          }).finally(() => {
            repeatClick.value = false;
          })
        }
      });
    }

    /**
     * 类型（1：加入会话；2：发起会话；）
     * @param groupId
     * @param type
     */
    const fetchAddUserGroup = (groupId: number | string, type: number): Promise<void> => {
      return new Promise(async (resolve, reject) => {
        // 切换回账号
        selectRobot.value && await toggleChatAccount(imTokenData.value)
        const {avatar, userId, realname, nickname: username} = userPlatformInfo.value;
        if (!avatar || !userId || !realname || !username) {
          reject('无用户信息')
        }
        let data = {
          groupId: groupId.toString(),
          "groupMembers": [
            {
              avatar,
              "isQuit": 0,
              "memberType": +type, // 成员类型：0 用户；1 机器人；2 超级管理员；3 管理员；4 客服；5 FAE；
              "skillGroupId": groupId.toString(),
              userId,
              realname,
              username,
              "userType": +type, // 0 用户；1 机器人；2 超级管理员；3 管理员；4 客服；5 FAE；
            }
          ],
          "type": +type,
          "userId": userId,
        }
        // 加入群聊
        API.joinUserGroupCs(data).then((res: any) => {
          const {errno, errmsg, data} = res;
          if (errno === 0) {
            resolve(data)
          } else {
            reject(errmsg)
          }
        }).catch(error => {
          reject(error)
        })
      })
    }


    // 切换群聊
    const toggleChatAccount = async (token: string, robot = false) => {
      // 先断开链接
      await im.disconnect()
      // 切换账号
      await im.connect({
        token
      }).then((res: any) => {
        console.log('链接成功, 链接用户 id 为: ', res.id, res);

        // 重新获取列表的未读消息
        if (!robot) {
          getExistedConversationListCore({content: {style: ''}})
        }
        return Promise.resolve(res)
      }).catch((error: any) => {
        return Promise.reject(error)
      });
    }

    // 加入群聊
    const confirmJoinGroup = () => {
      addUserToGroup(selectGroup.value.joinInitiated ? 1 : 2);
    }
    //获取用户信息
    const customerInfo = ref<any>({});
    const customerInfoForm = ref<any>({
      project: ['']
    });

    const getUserDetail = (e: any) => {
      customerInfo.value = {};
      let data = {
        userId: e.chat_type == 1 ? "c_" + e.user_id : "cs_" + e.user_id,
        // isCustomerClient:0,
      };
      API.getUserDetail(data).then((res: any) => {
        if (res.errno == 0) {
          customerInfo.value = res.data;
          if (!customerInfo.value.project.length) {
            customerInfo.value.project = ['']
          }
          // formEdit()
        }
      });
      //
    };
    const activeKey = ref<string>("1");
    // 加载初始页面消息
    const getMsgContentList = (date: any) => {
      const conversation = im.Conversation.get({
        targetId: chatroomId.value,
        type: RongIMLib.CONVERSATION_TYPE.GROUP,
      });
      const option = {
        timestamp: date,
        count: 20,
      };
      conversation.getMessages(option).then(async (result: any) => {
        if (date === 0) {
          msgList.value = [];
        }
        console.log("获取历史消息成功:", option, result);
        let hasMore = true;//result.hasMore;
        let resPage = hasMore;
        page.value.hasMore = resPage;
        let imgList = new Array();
        // 历史消息列表
        let list = result.list.map((item: any) => {
          item.show = false;
          if (item.messageType == "RC:ImgMsg") {
            imgList.push(item.content.imageUri);
          }
          item.sentTimeShow = showtime(item.sentTime);

          if (item.messageType == 'RC:RcCmd' && item.content.extra && typeof item.content.extra === 'string') {
            item.content.extra = JSON.parse(item.content.extra)
          }
          return item;
        });
        if (list.length > 0) {
          msgList.value = list.concat(msgList.value);
          let msgId = list[list.length - 1].messageUId;
          setTimeout(() => {
            clearUnreadMsg('', false, chatroomId.value)
            conversation.read().then(function () {
              // 发送同步消息
              conversation
                  .send({
                    messageType: "RC:SRSMsg",
                    content: {
                      //从消息里获取服务器端时间，以最近一条已读 message 为准
                      lastMessageSendTime: list[0].sentTime,
                      clear: true
                    },
                    // 定向发送给多端的自己，群里其他用户无需接收
                    directionalUserIdList: ["currentLoginUserId"],
                  })
                  .then((message: any) => {
                    // 获取未读数量
                    getUnreadCount()
                  });
            });
          }, 500);
          if (date == 0) {
            // 记录里存在图片
            const exist = list.some(({messageType}: any) => {
              if (messageType === "RC:ImgMsg") return true
            });
            if (exist) return true
          }
          scrollToBottom(msgId);
        }else{
          API.messageshistory({
            "group_id": chatroomId.value,
            "msgUId": msgList.value.length ? msgList.value[0].messageUId:'',
            "pageSize": 20
          }).then((res:any)=>{
            console.log('服务器聊天记录',res);
            page.value.hasMore = res.data.hasMore;
            list = res.data.list.map((item: any) => {
              item.show = false;
              if (item.messageType == "RC:ImgMsg") {
                imgList.push(item.imageUri);
              }
              item.sentTimeShow = showtime(item.sentTime);
              return item;
            });
            msgList.value = list.concat(msgList.value);
            let msgId = list[list.length - 1].messageUId;
            if (date == 0) {
              // 记录里存在图片
              const exist = list.some(({messageType}: any) => {
                if (messageType === "RC:ImgMsg") return true
              });
              if (exist) return true
            }
            scrollToBottom(msgId);
          });
        }
      });

    };
    const groupListItem = async (item: any, robot = false) => {
      showChatroom.value = false;
      textMsg.value = "";
      item.noReadNum = 0;
      remarkTxt.value = '';
      chatroomItem.value = item;
      chatroomId.value = item.groupId;
      reminded.value = mLocalStorage.getItem(`gm_reminded_${chatroomId.value}`) || {}
      page.value.pageNum = 1;
      getUserDetail(item);
      activeKey.value = "1";
      chatManage.value = [];
      getGroupUser(item.groupId);
      visibleMsg.value = false;
      msgStateShow.value = true;
      timingFun();

      if (!robot && !!selectRobot.value) {
        await toggleChatAccount(imTokenData.value)
        selectRobot.value = false;
      }
      msgList.value = [];
      getMsgContentList(0)
    };

    // 获取群成员
    const chatManage = ref<any[]>([]);
    let userStateInfo = reactive<any>({
      is_visit: 1,
      question_type: [],
      level: 1
    })
    watch(userStateInfo, () => {
      if (chatManage.value.length > 0 && !switchChat) updateGroupUser()
    })
    // 修改用户消息
    const GroupName = ref<string>('')
    const updateGroupUser = (remark = false): void => {
      const params: any = {
        groupId: chatroomId.value.toString(),
      };

      if (remark) {
        params.type = 1
        params.remark = remarkTxt.value
      } else {
        params.level = userStateInfo.level ? 1 : 2
        params.question_type = userStateInfo.question_type
      }

      try {
        API.updateGroupUser(params).then(({errno, errmsg, data: {msg}}: any) => {
          if (errno === 0)return;
          throw errmsg
        }).catch(err => {
          throw err
        })
      } catch (err: any) {
        message.error(err, 3)
      }
    }
    const remarkTxt = ref<string>('')
    const submitRemarkTxt = () => {
      updateGroupUser();
    }

    // 是否已经发送评价
    const isSendComment = ref(false)
    // 是开启评论功能
    const customerCommentFlag = ref(false)
    const getGroupUser = (id: string | number) => {
      if (!id) return
      let data = {
        groupId: id,
        user_id: userPlatformInfo.value.userId
      };
      API.getGroupUser(data).then((res: any) => {
        const {groupName, groupMembers, is_send_comment, customer_comment_flag, look = false} = res.data
        justLook.value = res.data.look
        isSendComment.value = is_send_comment === 1
        customerCommentFlag.value = customer_comment_flag !== 2
        if (groupName) {
          GroupName.value = groupName + (res.data.second_name ? (' 【' + res.data.second_name) + '】' : '')
        } else {
          groupMembers.forEach((item: any) => {
            if (item.userId !== userPlatformInfo.value.userId) {
              GroupName.value = item['userName'];
            }
          })
        }
        let json = res.data.groupMembers;
        userStateInfo.is_visit = res.data.is_visit

        // 过滤已隐藏删除的类型
        questionTypeValues.value = originalQuestionTypes.filter((item: any) => {
          return res.data.question_type.includes(item.id) || (item.is_show === 1 && item.deleted === 2)
        })
        userStateInfo.question_type = res.data.question_type.filter((item: number) => item > 0)

        userStateInfo.level = res.data.level === 1
        remarkTxt.value = res.data.remark

        const userType = [
          '用户',
          '机器人',
          '超级管理员',
          '管理员',
          '客服',
          '临时客服'
        ]
        setTimeout(() => {
          chatManage.value = json.map((item: any) => {
            item.userTypeName = userType[item.userType as number]
            return item
          });
          chatManageClean.value = json.filter((item: any) => {
            return item.userTypeName !== '超级管理员'
          })
        }, 500)

        // 延时结束会话设置
        delayState.value = res.data?.will_end_status;
        if (delayState.value === 1 && res.data?.will_end_time > Date.now()/1000) {
          executionDelayedSession(res.data?.will_end_time * 1000)
        } else {
          clearInterval(timer)
        }
      }).finally(() => {
        setTimeout(() => {
          switchChat = false;
        }, 1000)
      });
    };


    // 刷新列表
    const getExistedConversationList = useDebounceFn(async (event: any) => {
      if (event) {
        await getExistedConversationListCore(event);
      } else {
        runGroupList({
          userId: userPlatformInfo.value.userId.toString(),
        });
      }
      const {pageSize, pageNum} = allGroupParams;
      if (pageNum > 1) {
        allGroupParams.pageSize = pageSize * pageNum;
        allGroupParams.pageNum = 1;
      }
      await getUserAllGroupCore();
      if (pageNum > 1) {
        allGroupParams.pageSize = pageSize;
        allGroupParams.pageNum = pageNum;
      }
    }, 100)

    // 获取当前会话列表
    const getExistedConversationListCore = useDebounceFn((event: any, daley = 3000) => {
      return new Promise(function (resolve: any) {
        setTimeout(() => {
          let eventItem = event;
          let data = {
            userId: userPlatformInfo.value.userId.toString(),
            m_id: +(route.query.mid ?? -1)
          };
          getUserGroup(data).then((res: any) => {

            let dataItmeList = res.data.list.length > 0 ? res.data.list[0] : [];

            let list = res.data.list.forEach(async (item: any, index: number) => {
              let conversationItem = im.Conversation.get({
                targetId: item.groupId,
                type: RongIMLib.CONVERSATION_TYPE.GROUP,
              });
              await conversationItem.getUnreadCount().then((count: any) => {
                item.noReadNum = count;
                if (res.data.list.length - 1 === index) {
                  (groupList as any).value.push([]);
                  (groupList as any).value.pop()
                }
              });
            });
            groupList.value = res.data.list;
            if (eventItem.content.style == "question_accept_do") {
              groupListItem(groupList.value[0]);
            }

            if (groupList.value.length) {
              renderByGID()
            }
          });
        }, daley);
      });
    }, 100);

    const renderByGID = useDebounceFn(() => {
      if (route.query.gid) {
        let group = groupList.value.filter((item: any) => item.groupId == route.query.gid)[0];
        if (group) {
          groupListItem(group);
        }
        return false;
      } else if (tapTransferChat.value) {
        tapTransferChat.value = false;
        let group = groupList.value.filter((item: any) => item.groupId == chatroomId.value)[0];
        if (group) {
          groupListItem(group);
        }
      }
    }, 500);

    // 清除gid
    watch(chatroomId, () => {
      if (chatroomId.value == route.query.gid) {
        router.push({query: {}});
      }
    })
    watch(() => route.query.gid, () => {
      if (chatroomId.value == route.query.gid) {
        router.push({query: {}});
      }
    })

    const changeExistedConversationList = (msg: any) => {
      let dataItem = {};
      let list = groupList.value.filter((item: any) => {
        if (item.groupId == msg.targetId) {
          item.create_time = new Date().getTime(); //msg.sentTime;
          item.noReadNum = 0;
          item.content_struct.object_name = msg.messageType;
          item.content_struct.content = msg.content.content;
          dataItem = item;
        }
        return item.groupId != msg.targetId;
      });
      let dataItemList = [dataItem];
      groupList.value = dataItemList.concat(list);
    };
    const changeList = ref<boolean>(false);
    const playBodyMsgAudio = () => {
      ctx.emit("handlePlay", true);
    };
    const imStatus = ref(-1 )
    const imWatch = () => {
      // 添加事件监听
      im.watch({
        // 监听会话列表变更事件
        conversation(event: any) {
          if (
              imToken &&
              event.updatedConversationList.length > 0 &&
              (event.updatedConversationList[0].unreadMessageCount > 0 ||
                  event.updatedConversationList[0].notificationStatus > 0)
          ) {

            changeList.value = true;

          }
        },
        // 监听消息通知
        message(event: any) {
          // 新接收到的消息内容
          const message = event.message;
          const msg = [];
          msg[0] = message;
          const {
            targetId,
            messageType,
            content: {style, quitGroupUserId},
            messageDirection,
            receivedStatus,
            isOffLineMessage
          } = message;
          // 用来广播的
          if (targetId === "robot") return
          // 离线消息
          if (isOffLineMessage) {
            return
          }
          console.log("监听消息通知", event);
          // 判断多端已读消息
          if (message.content.clear) {
            groupList.value.some((item: any) => {
              if (item.groupId === message.targetId) {
                // console.log('message.groupId------------', message.messageUId);
                item['noReadNum'] = 0
                return true
              }
              return false
            })
          }
          effect.value = Math.random()

          // 延时会话
          if (message.messageType === 'RC:RcCmd' && ['delay', 'delay-cancel'].includes(message.content.style)) {
            executionDelayedSession(+message.content.content || 0)
            if (message.content.style === 'delay') {
              executionDelayedSession(+message.content.content * 1000 || 0)
            } else {
              delayState.value = 2
              delayedSession.value = ''
              clearInterval(timer)
            }
            return;
          }
          // 是否存在历史列表
          let hasMsg = msgList.value.find((element: any) => element.messageUId == message.messageUId);

          // 后台删除标签
          if (style === 'delTags' && !selectRobot.value) {
            organizeGroupList(targetId)
            cleanGroup()
            return;
          }


          // 删除客服
          if (message.content.style == "delCs") {
            // router.push({ path: "/", query: {} });
            window.location.href = `${process.env.VUE_APP_WEBSITE}/customerService_Login`;
            return false;
          }

          const msgTypes = ['RC:TxtMsg', 'RC:ImgMsg', 'RC:HQVCMsg', 'RC:FileMsg', 'RC:InfoNtf', 'RC:RcCmd'];

          const inNotice = quitGroupUserId?.length ? quitGroupUserId.includes(userPlatformInfo.value.userIdRC) : true;

          // 不是当前群的消息 && 固定消息类型 && 消息其它端未读
          if (chatroomId.value !== targetId && msgTypes.includes(messageType) && receivedStatus != 8) {
            // 刷新操作 || （退群操作 && 并且通知人里包含我）
            if (['refresh'].includes(style) || (['quitGroup', 'quitGroupMyself', 'question_accept_do'].includes(style) && inNotice)) {
              organizeGroupList(targetId)
              return false;
            }

            console.info('message--------', message);
            // 语音提示 收取别人的消息
            playMessageVoice(message)

            // 刷新列表？ question
            if (['question'].includes(style)) {
              getExistedConversationListCore(message, 500)
            }
            // getExistedConversationList(message);
            // 当前群里面
          } else if (chatroomId.value == targetId && msgTypes.includes(messageType) && !hasMsg) {// && message.receivedStatus != 8
            if (messageType == "RC:ImgMsg") {
              imgScroll.value = true;
              sendImg.value = true;
            }
            const online = localStorage.getItem("online") || 0;
            if (receivedStatus != 8 && +online == 3) {
              playMessageVoice(message)
            }
            // 系统通知退出操作
            if (messageType == 'RC:InfoNtf' && ['quitGroupMyself', 'quitGroup', 'invitation_accept_do', 'delTags'].includes(style)) {
              setTimeout(() => {
                getGroupUser(chatroomId.value)
              }, 3000);
            }
            if (['invitation'].includes(style)) {
              changeExistedConversationList(message);
              // 退群 && 通知人是否包含自己 && 并且 不是机器人
            } else if (['quitGroupMyself', 'quitGroup', 'question_accept_do', 'refresh'].includes(style) && inNotice && !selectRobot.value) {
              // console.log(selectRobot.value, message);
              // debugger
              // 清除群聊
              cleanGroup()
              tapTransferChat.value = false;
              return false;
            }

            if (!onShow.value) {
              return false;
            }

            // 解析撤回扩展消息字段 extra
            if (message.messageType == 'RC:RcCmd' && message.content.extra) {
              message.content.extra = JSON.parse(message.content.extra)
              // 删除消息
              handleWithdrawal(message.content.extra.messageUId)
            }

            // 存在就不加入聊天列表
            if (!hasMsg) {
              addImgMsg(message);
            }

            clearUnreadMsg(message.sentTime, true)
          } else if (message.messageType == "RC:SRSMsg") {
            clearUnreadMsg(message.sentTime)
          }

          // 加入群聊时创建
          if (['refreshList', 'invitation_accept_do'].includes(style)) {
            getExistedConversationList(message);
            queryUserAlone()
          } else {
            if (messageType !== 'RC:SRSMsg') {
              // 渲染列表未读和最新语
              renderGroupList(message);
            }
          }

          // 获取未读数量
          getUnreadCount()
        },
        // 监听 IM 连接状态变化
        status(event: any) {
          if (event.status == 6) {
            Modal.error({
              title: "温馨提示",
              content: "您的账号在其他设备登录, 本端被踢！",
              okType: "danger",
              okText: "确认",
              onOk() {
                router.push({path: "/", query: {uid: unionid}});
              },
            });
          }
        },
      });
    };

    const queryUserAlone = () => {
      API.queryUserAlone({
        "groupId": chatroomId.value,
        "userId": userPlatformInfo.value.userId + ''
      }).then((res: any) => {
        getGroupUser(chatroomId.value)
        if (res && res.errno === 0) {
          res.data.chat_type = 1;
          console.log(res.data.groupMembers);
          res.data.memberType = 0;
          res.data.groupMembers.some((item: any) => {
            if (+item.userId === +userPlatformInfo.value.userId && +item.userType === 2) {
              res.data.memberType = item.memberType
              console.log(item.userId, 'userId ------------------');
              return true
            }
            return false
          })
          chatroomItem.value = res.data
          console.log(chatroomItem.value);
        }
      })
    }
    const playMessageVoice = (message: any) => {
      console.log('触发语音提示')
      const {content: {style}, messageDirection} = message;

      // 语音提示 收取别人的消息
      if (!msgVoice.value && messageDirection == 2) {
        // playMsgAudio();
        //!selectRobot.value && 23/7/5 修改提示音触发条件 删除
        if (!['refresh', 'quitGroupMyself', 'quitGroup', 'invitation_accept_do', 'refreshList', 'delTags'].includes(style) && message.messageType !== 'RC:InfoNtf') {
          playBodyMsgAudio();
          setTimeout(() => {
            msgVoice.value = false;
          }, 800);
        }

      }
    }
    // 更新未读数量 和消息
    const renderGroupList = async (message: any) => {
      const {content, targetId, messageType, state} = message;
      let unreadNum: any = 0;
      // 更新未读
      // 如果时当前群聊就不用获取未读 渲染最近一条消息即可
      if (targetId !== chatroomId.value) {
        await getUnreadCountByGroup(targetId).then(count => {
          unreadNum = count;
        })
      }

      // 当前打开的tab页面
      if (activeTab.value === 'current') {
        modifyGroupList(groupList.value, message, unreadNum)
      } else {
        modifyGroupList((groupAllLists as any).value, message, unreadNum)
      }
    }

    // 删除会话
    const organizeGroupList = (targetId = ''): void => {
      // 列表删除会话
      if (activeTab.value === 'current') {
        groupList.value = groupList.value.filter((item: any) => item.groupId != targetId);
      } else {
        groupAllList.value = (groupAllLists as any).value.filter((item: any) => item.groupId != targetId);
      }
    }

    // 修改聊天列表
    const modifyGroupList = (data: any[], message: any, count = 0) => {
      const {content, targetId, messageType, state, sentTime, senderUserId} = message;

      const exist = data.some((item: any, index: number) => {
        if (item.groupId === targetId) {
          item.senderUserId = senderUserId
          // 修改未读数量
          if (activeTab.value === 'current') {
            item.noReadNum = count;
          }
          // 修改最后一条留言
          if (messageType !== "RC:SRSMsg") {
            if (messageType === "RC:RcCmd" && content.extra) { // 撤回消息
              item.content_struct.extra = content.extra
            } else {
              item.content_struct.content = content.content;
            }

            item.content_struct.messageType = messageType;
            item.content_struct.object_name = messageType;
            item.content_struct.state = state
            item.create_time = sentTime


            // 聊天跳到最上面
            if (index !== 0 && targetId !== chatroomId.value) {
              data.splice(index, 1);
              data.unshift(item);
            }
            return true;
          }

          return true;
        }
      })

      if (!exist) {
        if (selectRobot.value) {
          getUserAllGroupCore()
        } else {
          runGroupList({
            userId: userPlatformInfo.value.userId.toString(),
          });
        }

      }

      return data;
    }
    // 清除未读消息
    const clearUnreadMsg = (sentTime = '', send = false, groupId = '') => {
      var conversation = im.Conversation.get({
        targetId: groupId || chatroomId.value,
        type: RongIMLib.CONVERSATION_TYPE.GROUP,
      });
      conversation.read().then(function () {
        console.log("清除未读数成功"); // im.watch conversation 将被触发
        effect.value = Math.random()
        if (send) {
          // 发送同步消息
          conversation.send({
            messageType: "RC:SRSMsg",
            content: {
              //从消息里获取服务器端时间，以最近一条已读 message 为准
              lastMessageSendTime: sentTime,
              clear: true
            },
            // 定向发送给多端的自己，群里其他用户无需接收
            directionalUserIdList: ["currentLoginUserId"],
          });
        }
      }).then((message: any) => {
        console.log(message);
        // getExistedConversationList(message);
      });
    }


    // 获取群未读数量
    const getUnreadCountByGroup = (targetId: string): Promise<void> => {
      return new Promise((resolve, reject) => {
        let conversationItem = im.Conversation.get({
          targetId: targetId,
          type: RongIMLib.CONVERSATION_TYPE.GROUP,
        });
        conversationItem.getUnreadCount().then((count: any) => {
          return resolve(count);
        }).catch(err => {
          return reject(err);
        });
      })
    }

    // 获取全部未读数量
    const getUnreadCount = (): Promise<void> => {
      return new Promise((resolve, reject) => {
        // im.Conversation.getTotalUnreadCount(true, [3]).then((total: any) => {
        //   // console.log('------------获取未读数量----------', total, '------groupId-------', groupId);
        // });
      })
    }
    // 链接im
    const getImLink = (token: string) => {

      //发者后台获取或 Server API
      const user = {
        token: token || imTokenData.value,
      };
      im.connect(user)
          .then((user: any) => {
            console.log("链接成功, 链接用户 id 为: ", user.id);
            getImList();
            imWatch();
            /*
          // 获取聊天记录
          this.getMsgList(); */
            imStatus.value = 0
            effect.value = Math.random()
          })
          .catch((error: any) => {
            console.log("链接失败: ", error.code, error.msg);
            reconnectImLink();
          });
    };

    // 获取会话列表
    const getImList = () => {
      // runGroupList({
      //   userId: userPlatformInfo.value.userId.toString(),
      // });
      getUserAllGroupCore();
      getExistedConversationListCore({content: {style: ''}}, 0)
      getUnreadCount()
    };
    const msgImgList = ref<any>([]);
    const msgList = ref<any>([]);
    const page = ref<msgPage>({
      pageSize: 10,
      pageNum: 1,
      hasMore: false,
    });

    // 重连
    const reconnectImLink = () => {
      im.reconnect()
          .then((user: any) => {
            console.log("重新链接成功, 链接用户 id 为: ", user.id);
          })
          .catch((error: any) => {
            console.log("链接失败: ", error.code, error.msg);
          });
    };

    // 重新进入
    // const againUserGroup = () => {
    //   const data = {
    //     isCustomerClient: 1,
    //     groupId: chatroomId.value,
    //   };
    //   API.againUserGroup(data).then((res: any) => {
    //    console.log("再次进入", res);
    //   });
    // };
    let textMsg = ref<string>("");
    const singleText = ref();
    const {txt, setCaretPosition, getCursorPosition, deleteEmojiCore, addEmoji} = useDeleteEmoji(textMsg, singleText)
    // 删除表情
    const deleteEmoji = () => {
      deleteEmojiCore(textMsg.value)
    };
    //表情定义
    const hideEmoji = true;
    //表情定义
    const emojiList = EmojiJson;
    const emojiNames = EmojiJson.map((item) => item.alt);
    //替换表情符号为图片
    const replaceEmoji = (str: any) => {
      if (!str) {
        return;
      }
      const reg = /\[([^(\]|\[)]*)\]/g;

      const replacedStr = str
          .replace(/</g, "&lt;")
          .replace(/>/g, "/&gt;")
          .replace(reg, (item: any, index: any) => {
            const num = emojiNames.indexOf(item);
            if (num >= 0) {
              const row = emojiList[num];
              if (row.alt == item) {
                const onlinePath = "https://oss.emakerzone.com/emojiPng/"; //'/static/img/emoji/';
                const imgstr =
                    '<img src="' + onlinePath + row.url + '" style="width:24px;height:24px">';
                //console.log("imgstr: " + imgstr);
                return imgstr;
              }
            } else {
              return item;
            }
          })

          .replace(/\n/g, "<br>");
      //console.log(replacedStr);
      return replacedStr;
    };
    //添加表情
    const _addEmoji = (em: any) => {
      const emotionName = em.alt;
      let comment = textMsg.value;
      const newComment =
          comment.slice(0, textMsg.value.length) +
          emotionName +
          comment.slice(textMsg.value.length);
      textMsg.value = newComment;
    };

    // 删除表情
    const _deleteEmoji = () => {
      const pos = textMsg.value.length;
      const comment = textMsg.value;
      //console.log("234",pos,comment)
      let result = "";
      // cursor = 0;

      let emojiLen = 6;
      let startPos = pos - emojiLen;
      if (startPos < 0) {
        startPos = 0;
        emojiLen = pos;
      }
      const str = comment.slice(startPos, pos);
      //console.log("2345",str)
      const matchs = str.match(/\[([\u4e00-\u9fa5\w]+)\]$/g);
      //console.log("34567",matchs)
      // 删除表情
      if (matchs) {
        const rawName = matchs[0];
        const left = emojiLen - rawName.length;
        //console.log("1111",this.emojiNames)
        if (emojiNames.indexOf(rawName) >= 0) {
          const replace = str.replace(rawName, "");
          result = comment.slice(0, startPos) + replace + comment.slice(pos);
          // cursor = startPos + left;
        } else {
          let endPos = pos - 1;
          if (endPos < 0) endPos = 0;
          const prefix = comment.slice(0, endPos);
          const suffix = comment.slice(pos);
          result = prefix + suffix;
          // cursor = endPos;
        }
        // 删除字符
      } else {
        let endPos = pos - 1;
        if (endPos < 0) endPos = 0;
        const prefix = comment.slice(0, endPos);
        const suffix = comment.slice(pos);
        result = prefix + suffix;
        // cursor = endPos;
      }
      console.log(result);
      textMsg.value = result;
      // this.cursor = cursor;
    };
    //查看历史
    const moreHistory = () => {
      /* if (isBrowseCurrentMsg.value == 0) {
        loadHistory(1);
        return false;
      }
      let data = {
        isBrowseCurrentMsg: 0,
        isCustomerClient: 1,
        groupId: chatroomId.value,
      };
      API.changebrowsestatus(data).then((res: any) => {
        // isBrowseCurrentMsg=0;
        loadHistory(1);
      }); */
      console.log("历史消息");
      // loadHistory(1);
      const ViewTime = msgList.value[0].sentTime; //第一个的时间戳
      //本地模拟请求历史记录效果
      getMsgContentList(ViewTime);
    };
    //触发滑动到顶部(加载历史信息记录)
    const loadHistory = (e: any) => {
      if (!page.value.hasMore) {
        return;
      }
      const ViewTime = msgList.value[0].sentTime; //第一个的时间戳

      //本地模拟请求历史记录效果
      getMsgContentList(ViewTime);
    };
    // const textMsg = ref<string>("");
    const textPlaceholder = ref<string>(
        "请输入内容...\n按Enter键发送，按Ctrl+Enter键换行"
    );

    // const singleText = ref();
    // 回车发送文字消息
    const enterSendText = (e: any) => {
      console.log(123, e);
      if (e.keyCode == 13) {
        if (e.ctrlKey && e.keyCode == 13) {
          //用户点击了ctrl+enter触发
          // textMsg.value += "\n";
          const myField = singleText.value;
          if (myField.selectionStart || myField.selectionStart === 0) {
            let startPos = myField.selectionStart;
            let endPos = myField.selectionEnd;
            let content =
                myField.value.substring(0, startPos) +
                "\n" +
                myField.value.substring(endPos, myField.value.length);
            textMsg.value = content;
            nextTick(() => {
              myField.focus();
              myField.setSelectionRange(endPos + 1, endPos + 1);
            }); // 这句是重点, 圈起来
          } else {
            textMsg.value += "\n";
          }
          return false;
        }
        e.preventDefault(); // 阻止浏览器默认的敲击回车换行的方法
        // 执行一些逻辑方法
        if (!textSend.value) {
          sendText();
        }
      }
    };

    // 发送文字消息
    const sendText = () => {
      // this.hideDrawer();//隐藏抽屉
      if (!textMsg.value) {
        return;
      }
      // let content = this.replaceEmoji(this.textMsg);

      // display: flex;
      // let msg = {text:'<div style="align-items: center;word-wrap:break-word;">'+content+'</div>'}
      const msg = {
        type: "text",
        content: {
          content: textMsg.value,
        },
      };
      sendMsg(msg);
      timingFun();

      //关闭延时会话
      if(delayState.value ===1&&delayedSession.value!=''){
        delayState.value = 2
        delayedSession.value = ''
        clearInterval(timer)
      }


      // textMsg.value = ""; //清空输入框
      //console.log("发送消息：", textMsg.value);
      //console.log("聊天室id：", chatroomId);
      //console.log(RongIMLib);
    };
    // 发送消息
    const sendMsg = (msg: MsgAddItem) => {
      console.log("发送消息", msg);
      // 发送消息
      screenMsg(msg);
    };
    //聊天框操作
    const textSend = ref<boolean>(false);
    // 接受消息(筛选处理)
    const screenMsg = async (msg: MsgAddItem): Promise<any> => {
      if (textSend.value && msg.type == "text") {
        return Promise.reject('当前通道拥挤，请稍等...')
      }
      textSend.value = true;
      let members = {
        members: {
          userAvatar: userPlatformInfo.value.avatar,
          userName: userPlatformInfo.value.nickname,
        },
      };

      let msgContent = {
        content: {
          ...msg.content, // 消息内容
          ...members,
          chat_type: "group",
        },
      };
      let dataMessage = {
        messageType: "",
        messageDirection: 1,
        messageUId: "cyz-" + new Date().getTime(),
        loading: 1,
        fileUploadItem: msg.fileUploadItem,
        targetId: chatroomId.value,
        ...msgContent,
      };

      switch (msg.type) {
        case "text":
          dataMessage.messageType = RongIMLib.MESSAGE_TYPE.TEXT;
          textMsg.value = ""; //清空输入框
          break;
        case "voice":
          dataMessage.messageType = RongIMLib.MESSAGE_TYPE.HQ_VOICE;
          break;
        case "img":
          dataMessage.messageType = RongIMLib.MESSAGE_TYPE.IMAGE;
          dataMessage.content.content = msg.imgUploadURL;
          dataMessage.content.imageUri = msg.imgUploadURL;
          //console.log(msg.imgUploadURL,msgImgList.value);
          /* let url = ()msg.imgUploadURL as any).toString();
					this.msgImgList.push(url); */
          break;
        case "file":
          dataMessage.messageType = RongIMLib.MESSAGE_TYPE.FILE;
          break;
      }

      addTextMsg(dataMessage);
      changeExistedConversationList(dataMessage);
      scrollToBottom(dataMessage.messageUId);
      return new Promise((resolve, reject) => {
        sleep(300).then(() => {
          textSend.value = false;
          sendMessage(dataMessage).catch(() => reject('fail')).then(() => {
            resolve('ok')
          }).catch(err => {
            reject(err)
          });
        })
      })
    };
    const sendMessage = async (dataMessage: any) => {
      switch (dataMessage.messageType) {
        case "RC:TxtMsg":
          await sendMsgFun(dataMessage);
          break;
        case "RC:HQVCMsg":
          // pc端暂无录音发送
          break;
        case "RC:ImgMsg":
          console.log("发送前", dataMessage);
          await handleUploadMsg(dataMessage, 'img')
          break;
        case "RC:FileMsg":
          // console.log("文件消息发送前", dataMessage);
          await handleUploadMsg(dataMessage)
          break;
      }
    };

    const handleUploadMsg = async (dataMessage: any, type = 'file') => {
      return new Promise((resolve, reject) => {
        upLoadAvatarOss(dataMessage.fileUploadItem, type === 'file' ? 1 : 0).then(response => {
          console.log("response================>", response);
          if (type === 'file') {
            dataMessage.content.fileUrl = response;
          } else {
            dataMessage.content.content = response;
            dataMessage.content.imageUri = response;
          }

          sendMsgFun(dataMessage).then(() => {
            resolve('ok');
          }).catch(e => {
            reject(e)
          })
        }).catch(err => {
          console.log("上传文件失败", err);
          sendMsgErrorFun(dataMessage);
          reject(err)
        })
      })
    }

    const sendMsgCallback = (content: any): Promise<any> => {
      const promise = new Promise((resolve, reject) => {
        const chatRoom = im.Conversation.get({
          targetId: chatroomId.value,
          type: RongIMLib.CONVERSATION_TYPE.GROUP,
        });
        console.log("content ======》", content);
        chatRoom.send({...content,}).then((message: any) => {
          // console.log("发送文字消息成功", message);
          message.msgUId = content.messageUId;
          renderGroupList(message);
          resolve(message);
        }).catch((error: any) => {
          console.log("发送 s:person 消息失败", error.code, error.msg);
          // this.textSend=false;
          reject(content);
        });
      });
      return promise;
    };
    const sendMsgErrorFun = (dataMessage: any) => {
      msgList.value = msgList.value.map((item: any) => {
        if (item.messageUId == dataMessage.messageUId) {
          item.loading = 2;
        }
        return item;
      });
    };
    const sendMsgFun = (dataMessage: any) => {
      return new Promise((resolve, reject) => {
        sendMsgCallback(dataMessage).then(response => {
          msgList.value = msgList.value.map((item: any) =>
              item.messageUId == response.msgUId ? response : item
          );
          resolve(response)
        }).catch(err => {
          console.log("发送 s:person 消息失败", err);
          sendMsgErrorFun(err);
          reject(err)
        });
      })
    };
    const resend = (dataMessage: any) => {
      console.log(dataMessage);
      dataMessage.loading = 1;
      sendMessage(dataMessage);
    };
    // 添加文字消息到列表
    const addTextMsg = (msg: any) => {
      msgList.value.push(msg);
      scrollToBottom(msg.messageUId);
    };
    // 添加图片及其他消息到列表
    const addImgMsg = (msg: any) => {
      // msg.msg.content = this.setPicSize(msg.msg.content);
      // console.log("图片消息");
      if (msg.content.imageUri) {
        msgImgList.value.push(msg.content.imageUri);
      }
      msgList.value.push(msg);
      setTimeout(() => {
        scrollToBottom(msg.messageUId);
      }, 300);
    };
    // 添加文件消息
    const addFileMsg = (msg: any) => {
      msgList.value.push(msg);
      scrollToBottom(msg.messageUId);
    };
    /* // 添加语音消息到列表
		addVoiceMsg(msg:any):void{

			this.msgList.push(msg);
      scrollToBottom(msg.messageUId);
		}

		unshiftImgMsg(msg:any):void{
			this.msgList.push(msg);
		}
		//
		addRedEnvelopeMsg(msg:any):void{
			this.msgList.push(msg);
		} */
    // 点击发送
    const onKeyword = (e: any) => {
      console.log(e);
      const item = e;
      const msg = {
        type: "text",
        content: {
          content: item.keyword,
        },
      };
      sendMsg(msg);
    };
    // 播放语音
    const playMsgid = ref<string>("");
    const AUDIO: any = new Audio();

    const playVoice = (msg: any) => {
      playMsgid.value = msg.messageUId;
      AUDIO.src = msg.content.remoteUrl;
      /* nextTick(() => {
        AUDIO.play();
        AUDIO.onEnded((res: object) => {
          playMsgid.value = "";
        });
      }); */
      let second = msg.content.duration;
      let playPromise;
      playPromise = (AUDIO.play() as any)
          .then(() => {
            console.log("播放成功");
          })
          .catch(() => {
            console.log('语音播报失效,点击右上角"激活"按钮');
          });

      if (playPromise) {
        playPromise
            .then(() => {
              // 音频加载成功
              // 音频的播放需要耗时
              console.log(second);
              let tiemr: any = setInterval(() => {
                second--;
                if (second <= 0) {
                  AUDIO.pause();
                  playMsgid.value = "";
                  clearInterval(tiemr);
                }
              }, 1000);
            })
            .catch((e: any) => {
              // 音频加载失败
              console.error(e);
            });
      }
    };

    // 滚动到底
    const msgScroll = ref();
    const scrollToBottom = useDebounceFn((id: string) => {
      nextTick(() => {
        //console.log(id);
        // let container: any = msgScroll.value;
        // container.scrollTop = container.scrollHeight;
        const div = msgScroll.value;
        div.scrollTop = div.scrollHeight;
        (document.getElementById("muId-" + id) as any)?.scrollIntoView(true);
      });
    }, 10);
    // 上传
    const ossParameter = reactive<any>({
      endpoint: "oss.emakerzone.com",
      region: "oss-cn-shenzhen",
      accessKeyId: process.env.VUE_APP_AK,
      accessKeySecret: process.env.VUE_APP_AK_SECRET,
      bucket: "cloud-fae",
    });
    const fileChange = async (e: any) => {
      // console.log("上传文件", e, e.target.value);
      const thatItem = e;
      queueImgMsg(thatItem.target.files);
      // await sleep(500)
      (document.getElementById("fileInput") as HTMLFormElement).value = "";
    };
    const fileImgChange = (e: any) => {
      const thatItem = e;
      queueImgMsg(thatItem.target.files);
      setTimeout(() => {
        (document.getElementById("fileImg") as any).value = "";
      }, 500);
    };
    // 拖拽上传
    const dropFileChange = (e: any) => {
      // console.log("拖拽上传文件", e);
      // console.log("个数", e.dataTransfer.files);
      // const thatItem = { ...e };
      // console.log("拖拽文件", e.dataTransfer.files[0]);

      queueImgMsg(e.dataTransfer.files);
    };

    // 图片/文件消息队列
    const queueImgMsg = async (dataList: any) => {
      console.log(...dataList);
      if (dataList.length > 6) {
        message.error("文件发送数超过最大限度！每次最多发送6个。");
        return false;
      }
      const fileList: File[] = []
      dataList.forEach((item: File) => {
        fileList.push(item)
      })

      for (const item of fileList) {
        let filesContent = item;
        let lastType = filesContent.name.split(".");
        switch (lastType[lastType.length - 1]) {
          case "jpg":
          case "png":
          case "PNG":
          case "jpeg":
            console.log("图片", item);
            let fileReader = new FileReader();
            fileReader.readAsDataURL(filesContent); //根据图片路径读取图片
            await new Promise((resolve, reject) => {
              fileReader.onload = function (e) {
                const base64 = this.result;
                const img: any = new Image();
                img.src = base64;
                img.onload = function () {
                  let msg = {
                    type: "img",
                    content: {
                      // content:result,
                      // imageUri:result,
                      w: img.naturalWidth,
                      h: img.naturalHeight,
                    },
                    imgUploadURL: fileReader.result,
                    fileUploadItem: filesContent,
                  };
                  screenMsg(msg).then(() => {
                    resolve('ok')
                  });
                };
              };
            })
            break;
          default:
            // console.log("文件", item);
            let filePathType = lastType[lastType.length - 1];
            let msg = {
              type: "file",
              content: {
                name: filesContent.name,
                size: filesContent.size,
                fileUrl: "",
                type: filePathType,
              },
              fileUploadItem: filesContent,
            };
            await screenMsg(msg)
            break;
        }
      }
    };

    //
    async function upLoadAvatarOss(e: any, type: number) {
      const file = e;
      // type == 0 || type == 1 ? e.currentTarget.files[0] : e.dataTransfer.files[0];
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      // reader.readAsDataURL(file);

      return new Promise((resolve, reject) => {
        reader.onload = function (event: any) {
          const client = new OSS({
            region: "oss-cn-shenzhen",
            accessKeyId: process.env.VUE_APP_AK,
            accessKeySecret: process.env.VUE_APP_AK_SECRET,
            bucket: "cloud-fae",
            endpoint: "https://oss.emakerzone.com/",
            cname: true,
          });
          //
          // let filePath1 = filePath.lastIndexOf(".");
          // let filePath2 = filePath.length;
          // let filePathType = filePath.substring(filePath1, filePath2);

          // //图片名字 可以自行定义，这里是采用当前的时间戳 + 150内的随机数来给文件命名的
          // //console.log(name)
          // const aliyunFileKey = name
          //   ? dir + new Date().getTime() + Math.floor(Math.random() * 150) + "/" + name
          //   : dir + new Date().getTime() + Math.floor(Math.random() * 150) + filePathType;
          const obj = new Date().getTime(); //时间戳
          const key =
              "url/chat/" + (type == 0 ? "img/" : "file/") + obj + "/" + file.name; //  路径+时间戳+名
          const buffer = new OSS.Buffer(event.target.result);

          let lastType = file.name.split(".");
          let filePathType = lastType[lastType.length - 1];
          console.log(filePathType);
          let contentType = "";
          switch (filePathType) {
            case "xlsx":
              //console.log("文件格式：xlsx");
              contentType =
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
              break;
            case "xls":
              //console.log("文件格式：xls");
              contentType = "application/vnd.ms-excel";
              break;
            case "doc":
              //console.log("文件格式：xls");
              contentType = "application/msword";
              break;
            case "docx":
              //console.log("文件格式：xls");
              contentType =
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
              break;
            case "ppt":
              //console.log("文件格式：xls");
              contentType = "application/vnd.ms-powerpoint";
              break;
            case "pptx":
              //console.log("文件格式：xls");
              contentType =
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.template";
              break;
            case "pdf":
              //console.log("文件格式：xls");
              contentType = "application/pdf";
              break;
            case "jpg":
            case "png":
            case "PNG":
              contentType = "image/jpg";
              break;
            case "jpeg":
              //console.log("文件格式：图片格式");
              contentType = "image/jpg";
              break;
            case "mp3":
              //console.log("文件格式：mp3");
              contentType = "audio/*";
              break;
            default:
              //默认
              contentType = "application/x-www-form-urlencoded";
          }
          const headers = {
            "content-type": contentType,
          };

          async function put() {
            try {
              const result = await client.put(key, buffer, {headers});
              const httpUrl =
                  "url/chat/" + (type == 0 ? "img/" : "file/") + obj + "/" + key;
              resolve(result.url); //返回路径
            } catch (e) {
              reject(e);
              console.log(e);
            }
          }

          return put();
        };
      });
    }

    const showDownFile = (e: any) => {
      console.log(e);
      if (e.content.fileUrl) {
        window.open(e.content.fileUrl);
      } else {
        message.warning("发送中，请稍等");
      }
    };
    //表情
    const emojiBodyShow = ref<boolean>(false);
    const showEmoji = (e: any) => {
      emojiBodyShow.value = !emojiBodyShow.value;
    };

    // 时间处理
    const showtime = (time: any) => {
      // console.log("时间转换", time);

      let date =
          typeof time === "number"
              ? new Date(time)
              : new Date((time || "").replace(/-/g, "/"));
      let diff = (new Date().getTime() - date.getTime()) / 1000;
      let dayDiff = Math.floor(diff / 86400);

      let isValidDate =
          Object.prototype.toString.call(date) === "[object Date]" &&
          !isNaN(date.getTime());

      if (!isValidDate) {
        window.console.error("不是有效日期格式");
      }
      return (
          (dayDiff < 1 &&
              ((diff < 60 && "刚刚") ||
                  (diff < 120 && "1分钟前") ||
                  (diff < 3600 && Math.floor(diff / 60) + "分钟前") ||
                  (diff < 7200 && "1小时前") ||
                  (diff < 86400 && Math.floor(diff / 3600) + "小时前"))) ||
          (dayDiff === 1 && "昨天") ||
          (dayDiff < 7 && dayDiff + "天前") ||
          (dayDiff <= 30 && Math.ceil(dayDiff / 7) + "周前") ||
          (dayDiff > 30 && Math.ceil(dayDiff / 30) + "月前") ||
          (dayDiff > 365 && Math.ceil(dayDiff / 365) + "年前")
      );
    };

    // 信息
    const formEditTipe = ref<boolean>(false);
    const formEdit = () => {
      customerInfoForm.value = {...customerInfo.value};
      formEditTipe.value = true;
    };
    const formEditCancel = () => {
      formEditTipe.value = false;
      resetForm();
    };
    const formRef = ref();
    const formTypeRef = ref();
    const formState: any = reactive({
      level: true,
      question_type: '其它',
      is_visit: true,
    });
    const rules = {
      region: [
        {required: true, message: "请选择群聊状态", type: "number", trigger: "change"},
      ],
      email: [
        {
          message: "请输入正确的邮箱地址",
          type: "email",
          trigger: "blur",
        },
      ],
    };
    const onTypeSubmit = (groupId = '') => {
      sendTypeChange();
      // formTypeRef.value
      //     .validate()
      //     .then(() => {
      //       console.log("values", formState, toRaw(formState));
      //     })
      //     .catch((error: ValidateErrorEntity<FormState>) => {
      //       console.log("error", error);
      //     });
      Modal.confirm({
        content: "确定结束会话？",
        okType: "danger",
        okText: "确认",
        onOk() {
          return new Promise((resolve, reject) => {
            updateGroupState(false, groupId).then(res => {
              resolve('ok')
            }).catch(() => {
              reject('fail')
            });
          }).catch(() => console.log('Oops errors!'));
          // console.log("用户点击确定", formState);
          // updateGroupState(false, groupId);
          // updateGroupUser();
        },
        cancelText: "取消",
        onCancel() {
          console.log("用户点击取消");
        },
      });

    };

    const onSubmit = () => {
      formRef.value
          .validate()
          .then(() => {
            console.log("values", formState, toRaw(formState));
            console.log('-------------------', customerInfoForm.value);
            let data = {
              // ...customerInfo.value,
              userId: customerInfoForm.value.userId.toString(),
              name: customerInfoForm.value.nickname,
              realName: customerInfoForm.value.realname,
              mobile: customerInfoForm.value.mobile,
              email: customerInfoForm.value.email,
              company: customerInfoForm.value.company,
              job: customerInfoForm.value.job,
              remark: customerInfoForm.value.remark,
              role: customerInfoForm.value.role,
              level: customerInfoForm.value.level,
              project: customerInfoForm.value.project,
              type: 3
            };
            API.modifyUser(data).then((res: any) => {
              //// console.log(res)
              if (res.errno == 0) {
                formEditTipe.value = false;
                customerInfo.value = {...customerInfoForm.value};

                formEditCancel();
              } else {
                Modal.error({
                  title: "温馨提示",
                  content: res.errmsg,
                  okType: "danger",
                  okText: "确认",
                  onOk() {
                    console.log("ok");
                  },
                });
              }
            });
          })
          .catch((error: ValidateErrorEntity<FormState>) => {
            console.log("error", error);
          });
    };
    const resetForm = () => {
      formRef.value.resetFields();
    };
    // 解散群组
    const askRemoveGroup = () => {
      Modal.confirm({
        content: "确定解散该群聊？",
        okType: "danger",
        okText: "确认",
        onOk() {
          return new Promise(async (resolve, reject) => {
            await removeGroup();
            resolve('ok')
          }).catch(() => console.log('Oops errors!'));
        },
        cancelText: "取消",
        onCancel() {
          console.log("用户点击取消");
        },
      });
    };
    //退出群聊
    const askQuitGroup = () => {
      Modal.confirm({
        content: "确定退出群聊？",
        okType: "danger",
        okText: "确认",
        onOk() {
          console.log("用户点击确定");
          returnUserGroup();
        },
        cancelText: "取消",
        onCancel() {
          console.log("用户点击取消");
        },
      });
    };

    const returnUserGroup = () => {
      let data = {
        groupId: chatroomItem.value.groupId,
        userId: userPlatformInfo.value.userId.toString(),
        quitType: 1,
      };
      API.delUserGroup(data).then((res: any) => {
        // 清除记录
        cleanGroup()
      });
    };

    // 清除当前会话
    const cleanGroup = useDebounceFn(() => {
      message.success("操作成功！");
      msgList.value = [];
      chatroomItem.value = {};
      page.value.pageNum = 1;
      changeList.value = false;
      showChatroom.value = true;
      organizeGroupList(chatroomId.value)
      chatroomId.value = "";
    }, 200)

    const removeGroup = async () => {
      if (!chatroomId.value) {
        return Promise.reject();
      }
      let data = {
        groupId: chatroomId.value,
        userId: userPlatformInfo.value.userId.toString(),
      };
      await API.removeUserGroup(data).then((res: any) => {
        console.log("解散", res);
        if (res.errno == 0) {
          // cleanGroup()
        } else {
          message.error(res.errmsg);
        }

      });
    };
    // 修改群组状态
    const updateGroupState = (go: boolean, groupId = '', state = 2) => {
      //console.log(item)
      // uni.showLoading({
      // 	title:'请稍等...'
      // })
      groupId = groupId || chatroomId.value
      let data = {
        groupId: groupId,
      } as any;
      data.state = state
      data.isCustomerClient = 0

      // 清除未读消息
      clearUnreadMsg('', false, groupId)

      return new Promise((resolve, reject) => {
        API.updateGroupState(data).then((res: any) => {
          if (res.errno == 0) {
            if (groupId === chatroomId.value) {
              msgList.value = [];
              chatroomItem.value = {};
              chatroomId.value = "";
              page.value.pageNum = 1;
              changeList.value = false;
              // runGroupList({
              //   userId: userPlatformInfo.value.userId.toString(),
              // });
            }
            let timer = setTimeout(() => {
              getExistedConversationList(null)
              clearTimeout(timer)
              return resolve('ok')
            }, 500)
          } else {
            Modal.error({
              title: "温馨提示",
              content: res.errmsg,
              okType: "danger",
              okText: "确认",
              onOk() {
                console.log("ok");
              },
            });
            return resolve('fail')
          }
        });

      })
    };
    // 弹出转发
    const visibleSearch = ref<boolean>(false);
    const serviceModal = () => {
      if (repeatClick.value) {
        return;
      }
      repeatClick.value = true;
      userSearch.value = '';
      getUserService(2);
    };
    const serviceDataList = ref<any>([]);
    const serviceDataCount = ref<number>(0)
    const getUserService = (type: number, show = true) => {
      sendTypeChange();
      let data = {
        search: userSearch.value,
        userId: userPlatformInfo.value.userId.toString(),
        accessType: type, //接入模式：0 无；1 转发；2 转接；
        groupId: chatroomId.value,
      };
      if (serviceDataList.value.length) {
        filterAddMember(serviceDataList.value, type, userSearch.value)
        return
      }
      API.getMailList(data).then((res: any) => {
        console.log("获取-添加\转接\转发-通讯列表", res);
        if (res.errno == 0) {
          repeatClick.value = false;
          console.log(userSearch.value, type);
          serviceDataCount.value = res.data.list.length
          // debugger
          filterAddMember(res.data.list, type, '', show)
          // debugger;
        }
      }).finally(() => {
        repeatClick.value = false;
      });
    };

    watch(visibleSearch, (val) => {
      if (!val) {
        serviceDataList.value = []
        getUserService(2, false)
      }
    })
    // 筛选
    const filterAddMember = (list: any [], type = 1, searchVal = '', show = true) => {
      if (show) {
        if (type == 0 || type == 1) {
          visibleAddUserSearch.value = true;
        } else if (type == 2) {
          visibleSearch.value = true;
        }
      }

      if (userSearch.value) {
        serviceDataList.value.forEach((mitem: any) => {
          if (mitem.GroupMember) {
            mitem.GroupMember.forEach((smitem: any) => {
              mitem.show = false;
              smitem.show = false;
              list.forEach((fitem: any) => {
                if (mitem.group_id === fitem.group_id) {
                  if (fitem.GroupMember) {
                    mitem.show = true;
                    fitem.GroupMember.forEach((sfitem: any) => {
                      if (sfitem.user_id === smitem.user_id) {
                        if (searchVal) {
                          smitem.show = smitem.real_name.includes(searchVal)
                        } else {
                          smitem.show = true;
                        }
                      }
                    })
                  }
                }
              })
            })
          }
        })
      } else {
        serviceDataList.value = list.map((item: any) => {
          item.showItem = false;
          item.show = true;
          console.log(item.GroupMember);
          item.GroupMember = item.GroupMember || []
          item.GroupMember.forEach((sitem: any) => {
            sitem.show = true;
            return sitem;
          });
          return item;
        });
      }
    }
    const userSearch = ref<string>("");
    const getUser = (type: number) => {

      getUserService(type);
    };
    // 选择客服
    const serviceClick = (item: any) => {
      console.log(item);
    };
    const faeItem = ref<any>({});

    const inviteLoading = ref<boolean>(false);
    const handleInvitationOk = () => {
      inviteLoading.value = true
      chatItem(faeItem.value, 2);
    };
    //
    const chatItem = (e: any, type: number) => {
      console.log("转接/转发", e, type);
      let choiceType = type == 2 ? "转接" : "转发";
      if (type == 2) {
        chatTransfer(e.user_id);
      }
    };
    // 转接
    const chatTransfer = (id: number) => {
      let data = {
        groupId: chatroomId.value,
        userId: userPlatformInfo.value.userId.toString(),
        otherUserId: id.toString(),
      };
      API.transferChat(data).then((res: any) => {
        //// console.log("转接",res)
        visibleSearch.value = false;
        if (res.errno == 0) {
          message.success("转接请求已发送！");
        } else {
          message.error(res.errmsg);
        }
      }).finally(() => {
        setTimeout(() => {
          inviteLoading.value = false;
        }, 500)
      })
    };
    // 邀请
    const addUserVal = ref<any>([]);
    watch(addUserVal, () => {

    })
    const serviceAddModal = () => {
      userSearch.value = '';
      getUserService(1); //0，按后端意思改成1
    };
    const visibleAddUserSearch = ref<boolean>(false);
    watch(visibleAddUserSearch, (val) => {
      addUserVal.value = [];
      if (!val) {
        serviceDataList.value = []
        getUserService(1, false)
      }
    })
    //点击删除
    const closeUser = (e: any) => {
      console.log(e, 'del');
      let data = addUserVal.value.filter((item: any) => item.user_id != e.user_id);
      addUserVal.value = data;
    };

    watch(addUserVal, () => {
      const exists = new Map();
      addUserVal.value.forEach((item: any, index: number) => {
        if (exists.has(item.user_id.toString())) {
          addUserVal.value.splice(index, 1)
          message.warning('该客服已存在！')
        } else {
          exists.set(item.user_id.toString(), 1)
        }
      })
    })
    const onUserItemChange = (e: any) => {
      console.log(addUserVal.value, e, serviceDataList.value);
    };

    const handleUserOk = () => {
      if (addUserVal.value.length == 0) {
        message.error("请选择需要添加的成员！");
        return;
      }
      inviteLoading.value = true;

      addUserGroup();
    };
    // 添加成员
    const addUserGroup = () => {
      //// console.log(this.addMailList)
      // uni.showLoading({
      // 	title:'请稍等...'
      // })
      let list = addUserVal.value.map((item: any) => {
        let itemJson = {
          userAvatar: item.avatar,
          userId: item.user_id,
          userName: item.real_name,
          skillGroupId: item.group_id,
        };
        return itemJson;
      });
      let data = {
        groupId: chatroomId.value,
        userId: userPlatformInfo.value.userId,
        groupMembers: [...list],
      };
      API.joinUserGroup(data).then((res: any) => {
        console.log("拉入群聊回调：", res);

        if (res.errno == 0) {
          message.success("添加请求已发送！");
          visibleAddUserSearch.value = false;
          addUserVal.value = [];
        } else {

          message.error(res.errmsg);
        }
      }).finally(() => {
        repeatClick.value = false;
        inviteLoading.value = false;
      });
    };
    // 时间处理
    const stateTimeFun = ref<any>();
    const msgStateType = ref<number>(1);
    const msgStateTime = ref<string>("00:00");
    const msgStateItem = ref<any>({});
    const msgStateShow = ref<boolean>(true);

    const seeMsgState = (item: any) => {
      console.log("change", item);
      visibleMsgId.value = item.messageUId;
      let data = {
        msgUid: item.messageUId,
        extra: item.content.extra,
      };
      API.getMsgState(data).then((res: any) => {
        console.log("消息详情", res);
        // status 消息状态（1 已发送；2 已完成；3 已取消，非消息发送失败；4 已失效，非消息发送失败）
        if (res.errno == 0) {
          // uni.hideLoading()
          let json = res.data;
          msgStateItem.value = json;
          msgStateType.value = json.status;
          let msgTips = json.style == "question" ? "转接" : "邀请";
          if (json.status == 1) {
            if (item.messageDirection == 1) {
              message.success("等待对方接受！");
            } else {
              seeMsgStateTime(json.unix_time);
              visibleMsg.value = true;
            }
          } else if (json.status == 2) {
            visibleMsg.value = false;
            message.error("您来晚啦！该" + msgTips + "已接受");
          } else if (json.status == 3) {
            visibleMsg.value = false;
            message.error("该" + msgTips + "已拒绝");
          } else if (json.status == 4) {
            visibleMsg.value = false;
            message.error("您来晚啦！该" + msgTips + "已失效");
          }

        } else {

        }
      });
    };
    const visibleMsg = ref<boolean>(false);
    const visibleMsgId = ref<string>("");
    // 时间处理
    const seeMsgStateTime = (time: number) => {
      clearInterval(stateTimeFun.value);
      let endTime = time; //new Date(time).getTime() + 300 * 1000;
      let nowTime = new Date().getTime();
      let stateTime = (endTime - nowTime) / 1000;
      let stateTimeVal = "00:00";
      let minuteTime = "00"; // 分
      let secondTime = "00"; // 小时
      stateTimeFun.value = setInterval(() => {
        if (stateTime > 0) {
          // stateTime = endTime-new Date().getTime()
          minuteTime =
              Math.floor(stateTime / 60) < 10
                  ? "0" + Math.floor(stateTime / 60)
                  : Math.floor(stateTime / 60).toString();
          secondTime =
              Math.floor(stateTime % 60) < 10
                  ? "0" + Math.floor(stateTime % 60)
                  : Math.floor(stateTime % 60).toString();
          msgStateTime.value = minuteTime + ":" + secondTime;

          seeMsgStateTime(time);
          msgStateShow.value = false;
        } else {
          visibleMsg.value = false;
          let msgTips = msgStateItem.style == "question" ? "转接" : "邀请";
          message.error("该" + msgTips + "已失效");
          minuteTime = "00";
          secondTime = "00";
          msgStateTime.value = minuteTime + ":" + secondTime;
          msgStateType.value = 4;
          clearInterval(stateTimeFun.value);
        }
      }, 1000);
    };
    const tapTransferChat = ref<boolean>(false);
    const tapTransfer = (type: number) => {
      //// console.log(item,type);
      if (msgStateItem.value.senderUserId == userPlatformInfo.value.userId.toString()) {
        message.error("不可操作！");
        return;
      }
      let data = {
        confirm: type,
        extra: msgStateItem.value.content_struct.extra,
        msgUid: visibleMsgId.value,
        userId: userPlatformInfo.value.userId,
      };
      API.tapTransferChat(data).then((res: any) => {
        // uni.hideLoading()
        clearInterval(stateTimeFun.value);
        console.log("转接/邀请操作", res, res.data)

        if (res.errno == 0) {

          visibleMsg.value = false;
          let msgTips = msgStateItem.style == "question" ? "转接" : "邀请";
          message.success("已接受" + msgTips);
          msgList.value = [];
          chatroomItem.value = {};
          chatroomId.value = res.data.groupId;
          page.value.pageNum = 1;
          tapTransferChat.value = true;
          changeList.value = false;
          runGroupList({
            userId: userPlatformInfo.value.userId.toString(),
          });
          router.push({
            query: {gid: msgStateItem.value.content_struct.extra},
          });
        } else if (res.errno == 401) {
          visibleMsg.value = false;
          let msgTips = msgStateItem.style == "question" ? "转接" : "邀请";
          message.success("已拒绝" + msgTips);
        } else {
          message.error(res.errmsg);
        }
      });
    };
    const servicName = ref<string>("");
    const onServicSearch = async () => {
      // if (servicName.value == "") {
      //   message.error("请输入要搜索的内容");
      //   return false;
      // }
      // msgList.value = [];
      // chatroomItem.value = {};
      // chatroomId.value = "";
      page.value.pageNum = 1;
      changeList.value = false;
      // 搜索
      allGroupParams.pageNum = 1;
      if (!servicName.value) {
        return
      }

      getUserAllGroupCore(true);
    };
    // 查看聊天记录详  情
    const visibleHistory = ref<boolean>(false);
    const historyTitle = ref<string>("");
    const historyList = ref<any>([]);
    const seeMsgRecord = (item: any) => {
      console.log(item);
      sendTypeChange();
      historyTitle.value = item.content.content;
      let data = {
        id: item.content.extra,
      };
      API.seeMessageRecord(data).then((res: any) => {
        console.log("聊天记录", res);

        if (res.errno == 0) {
          historyList.value = res.data;
          visibleHistory.value = true;
        }
      });
    };

    const showNotifyUser = ref<boolean>(false);
    // 获取通知
    const getSendAsk = () => {
      showNotifyUser.value = true;
      // Modal.confirm({
      //   title: "温馨提示",
      //   content: "确定给用户发送消息提醒通知？",
      //   okType: "danger",
      //   okText: "确认",
      //   onOk() {
      //     console.log("用户点击确定");
      //     getSendMsg();
      //   },
      //   cancelText: "取消",
      //   onCancel() {
      //     console.log("用户点击取消");
      //   },
      // });
    };
    const getSendMsg = () => {
      let data = {
        userId: unionid,
        groupId: chatroomId.value,
        isCustomerClient: 0, //是否客户端 1 是 0 否
      };
      API.getSendMsg(data).then((res: any) => {
        if (res.errno == 0) {
          serviceDataList.value = [];
          message.success("已发送提醒！");
        }
      });
    };
    // 删除成员
    const delAsk = (e: any) => {
      console.log(e);
      Modal.confirm({
        title: "温馨提示",
        content: "确定删除选择成员？",
        okType: "danger",
        okText: "确认",
        onOk() {
          console.log("用户点击确定");
          delUserGroup(e);
        },
        cancelText: "取消",
        onCancel() {
          console.log("用户点击取消");
        },
      });
    };
    const delUserGroup = (e: any) => {
      let data = {
        groupId: chatroomId.value,
        userId: e.userId.toString(),
        quitType: 2,
      };
      API.delUserGroup(data).then((res: any) => {
        if (res.errno == 0) {
          message.success("删除成功！");
          chatManage.value = chatManage.value.filter(
              (item: any) => item.userId != e.userId
          );
        } else {
          message.error(res.errmsg);
        }
      });
    };

    // 拖拽上传
    const dragTxt = ref<string>("拖动到此处 上传文件");
    const dragShow = ref<boolean>(false);

    // 复制文件
    const pasteHasFile = ref<boolean>(false);
    const handlePaste = (event: any) => {
      console.log(event.clipboardData.files);
      queueImgMsg(event.clipboardData.files);
      pasteHasFile.value = event.clipboardData.files.length > 0;
      if (event.clipboardData.files.length > 0 && textMsg.value.length > 0) {
        setTimeout(() => {
          textMsg.value = textOleMsg.value;
        }, 500);
      }
    };
    const textOleMsg = ref<string>("");
    const textMsgChange = (text: any) => {
      textOleMsg.value = text;
      console.log("文件已复制");
    };

    const msgVoice = ref<boolean>(false);

    //加载图片回调
    const sendImg = ref<boolean>(false);
    let imgScroll = ref<boolean>(false);
    const msgImgLoad = (item: any) => {
      // eslint-disable-next-line no-debugger
      sendImg.value = false;
      if (msgList.value.length <= 20) {
        scrollToBottom(msgList.value[msgList.value.length - 1].messageUId)
      }
    }

    // vuex
    const store = useStore();
    watch(
        () => store.state.online,
        (val, old) => {
          console.log(val, old);
        }
    );

    // 监听5分钟
    const monitorChange = ref();
    const timingFun = () => {
      clearInterval(monitorChange.value);
      changeLine(1);
      monitorChange.value = setTimeout(() => {
        console.log("5分钟未操作");
        changeLine(3);
      }, 5 * 60 * 1000); // *60*1
    };
    window.addEventListener("setItemEvent", (e: any) => {
      //console.log("localStorage值发生变化后触发:", e, e.newValue);
      if (e.key == "online" && e.newValue == 1) {
      }
    });
    // 监听状态变化
    const sendTypeChange = () => {
      console.log(localStorage.getItem("online"));
      // timingFun();
      let lineType = localStorage.getItem("online") as any;
      if (lineType == 1) {
        clearInterval(monitorChange.value);
        monitorChange.value = setTimeout(() => {
          console.log("5分钟未操作");
          changeLine(3);
        }, 5 * 60 * 1000);
      } else {
        timingFun();
      }
    };
    // 用户在线状态
    const changeLine = (e: number) => {
      //// console.log('type',e)
      let data = {
        userId: userPlatformInfo.value.userId.toString(),
        is_online: e,
      };
      API.changeMineType(data).then((res: any) => {
        // store.state.online = e;
        //console.log("在线", e);
        localStorage.setItem("online", JSON.stringify(e));
        //console.log("状态修改成功", res);
      });
    };

    const nanumb = ref(0);
    const testbtn = () => {
      console.log("触发点击方法", chatroomId.value);

      console.log("进入1", nanumb.value);

      return new Promise(function (resolve: any) {
        setTimeout(() => {
          nanumb.value = nanumb.value + 1;
          console.log("进入2", nanumb.value);
          resolve();
        }, 1000);
      });
    };

    // 切换会话tab
    const activeTab = ref<string>('current')
    const currentRef = ref()
    const changeActiveTab = (activeKey: string): void => {
      activeTab.value = activeKey
      if (activeKey === 'current') {
        // 滑动条到最上面
        nextTick(() => {
          currentRef.value.$el.querySelector('.gm-position').scrollTo(0, 0)
        })
      }
      // getExistedConversationList('')
    }

    // 提醒
    const selectNotifyUser = ref<any []>([])
    const notifyLoading = ref<boolean>(false)
    const reminded = ref<any>(mLocalStorage.getItem(`gm_reminded_${chatroomId.value}`) || {})
    /**
     * 是否1分钟已提示过
     * @param id
     */
    const getNotificationStatus = (id: string) => {
      const time = reminded.value[`${id}`];
      if (!time) {
        return false
      } else {
        return Date.now() - time < 1 * 60 * 1000
      }
    }
    const handleNotifyUser = useDebounceFn((id = ''): void => {
      // selectNotifyUser.value = [...selectNotifyUser.value, `${id}`]
      selectNotifyUser.value = [`${id}`]

      if (notifyLoading.value) {
        return;
      }
      if (getNotificationStatus(`${id}`)) {
        message.warning('您的操作太频繁，请在1分钟后操作')
        return;
      }
      if (selectNotifyUser.value.length <= 0) {
        message.warning('请先选择需要提醒的群员')
        return;
      } else {
        notifyLoading.value = true;
        const userIds = selectNotifyUser.value
        // 提交数据库
        let data = {
          userIds,
          groupId: chatroomId.value,
          isCustomerClient: 0,//是否客户端 1 是 0 否
          channelType: 2,
        }
        API.getSendMsg(data).then((res: any) => {
          if (res.errno == 0) {
            selectNotifyUser.value = [];
            message.success('通知成功！')
            showNotifyUser.value = false;

            userIds.forEach(id => {
              reminded.value[`${id}`] = Date.now()
            })
            console.log(reminded.value);
            mLocalStorage.setItem(`gm_reminded_${chatroomId.value}`, unref(reminded), 1)
          }
        }).finally(() => {
          notifyLoading.value = false;
        })
      }
    }, 1000)
    const chatManageClean = ref<any []>([])
    // 获取群人数
    const getGroupMemberCount = (arr: any [], is_online = false) => {
      return arr.filter((show, is_online) => {
        return is_online ? (show && is_online === 1) : show;
      }).length
    }

    const shareModal = ref<boolean>(false)
    const shareAddModal = () => {
      shareModal.value = true;
    }

    const commentLoading = ref(false)
    // 发送评价邀请
    const sendUserEvaluation = () => {
      commentLoading.value = true
      API.sendGroupComment({
        groupId: chatroomId.value,
        type: 0
      }).then(res => {
        // console.log(res);
        isSendComment.value = true
        message.success('邀请用户评价已发送')
      }).finally(() => {
        commentLoading.value = false
      })
    }


    // 撤回消息
    const handleWithdrawal = (mid: any) => {
      let index = msgList.value.length - 1;
      while (index >= 0) {
        if (msgList.value[index] && msgList.value[index].messageUId === mid) {
          msgList.value.splice(index, 1)
          return;
        }
        index--;
      }
    }
    // 获取消息是否超过两分钟
    const exceedTwoMinutes = (sentTime: number) => {
      return (Date.now() - sentTime) < 60 * 2 * 1000
    }

    // 重新编辑
    const reEdit = (txt: string) => {
      (textMsg as any).value = textMsg.value + specialSymbol(txt)
      singleText.value.focus()
    }

    /**
     * 提交修改用户信息
     * @param field
     * @param val
     * @param item
     */
    const submitUserDetail = (field: string, val: string, item: any) => {
      API.modifyUser({
        ...item,
        name: item.userName,
        [field]: val,
        type: 2,
        userId: `${item.userId}`
      }).then((res: any) => {
        if (res.errno == 0) {
          item[field] = val
        } else {
          Modal.error({
            title: "温馨提示",
            content: '服务错误！',
            okType: "danger",
            okText: "确认",
            onOk() {
              console.log("ok");
            },
          });
        }
      });
    }


    const handleMoreActions = (val: any) => {
      console.log(val);
      switch (val.type) {
        case 'del':
          updateGroupState(false, val.row.groupId, 4)
          break
        case 'finish':
          onTypeSubmit(val.row.groupId)
          break
        default:
          break;
      }
    }

    const showSearch = ref<boolean>(false)
    const searchAll = ref(false);
    /**
     * 打开搜索
     */
    const handleOpenSearch = (isAll = false) => {
      searchAll.value = isAll
      console.log(isAll);
      showSearch.value = true
    }

    const selectOption = (value: any, options: any) => {
      console.log(options, value);
      userStateInfo.question_type = options.map(({key}: any) => key)
    }


    const changeTabClick = async (tab: string) => {
      switch (tab) {
        case "current":
          // 切换回账号
          selectRobot.value && await toggleChatAccount(imTokenData.value)
          await getExistedConversationListCore({content: {style: ''}}, 0);
          break
        case "end":
        case "all":
          groupAllList.value = []
          allGroupParams.pageNum = 1
          allGroupParams.group_state = tab === 'end' ? 2 : 0
          await getUserAllGroupCore();
          break
      }
    }

    const fileMark = ref()
    const uploadMark = async ({file}: any) => {
      const res = await upLoadAvatarOss(file, 0)
      fileMark.value = [res]
      customerInfoForm.value.remark = res

      console.log('sssssssssss', customerInfoForm.value.remark);
    }

    const justLook = ref(true)
    const isDrag = ref(false);

    const changeDrag = (v: boolean) => {
      isDrag.value = v
    }

    const delayState = ref(2)
    const delayedSessionFnc = () => {
      API.delayedSession({
        group_id: chatroomId.value,
        status: delayState.value === 1 ? 2 : 1
      }).then(res => {
        message.success('操作成功')
      })
    }
    const delayedSession = ref('')
    let timer = 0
    const executionDelayedSession = (t: number) => {
      delayState.value = 1;
      clearInterval(timer)
      if (t) {
        timer = setInterval(() => {
          delayedSession.value = countDownFnc(t)
          if(delayedSession.value==='00:00:00'){
            delayState.value = 2
            delayedSession.value = ''
            clearInterval(timer)
          }
        }, 1000)
      }
    }

    const countDownFnc = (d: number) => {
      const nowTime = +new Date(); //返回当前时间总的毫秒数
      const times: number = (d - nowTime) / 1000; //times是剩余时间总的秒数
      let h = parseInt(`${times / 60 / 60}`); //时
      const hs = h < 10 ? '0' + h : h;
      let m = parseInt(`${times / 60 % 60}`); //分
      const ms = m < 10 ? '0' + m : m;
      let s = parseInt(`${times % 60}`); //秒
      const ss = s < 10 ? '0' + s : s;

      return `${hs}:${ms}:${ss}`
    }

    return {
      delayedSessionFnc,
      delayState,
      delayedSession,
      changeDrag,
      isDrag,
      justLook,
      uploadMark,
      fileMark,
      changeTabClick,
      minutesSeconds,
      sysSetting: inject("sysSetting"),
      selectOption,
      searchAll,
      showSearch,
      handleOpenSearch,
      handleMoreActions,
      reminded,
      submitUserDetail,
      reEdit,
      exceedTwoMinutes,
      handleWithdrawal,
      getCursorPosition,
      currentRef,
      shareModal,
      shareAddModal,
      inviteLoading,
      notifyLoading,
      repeatClick,
      handleNotifyUser,
      selectNotifyUser,
      chatroomId,
      changeActiveTab,
      activeTab,
      testbtn,
      addEmoji,
      emojiList,
      showEmoji,
      emojiBodyShow,
      deleteEmoji,
      replaceEmoji,
      showConsult,
      userPlatformInfo,
      msgImgList,
      msgList,
      loadHistory,
      textMsg,
      textPlaceholder,
      enterSendText,
      sendTypeChange,
      sendText,
      singleText,
      playMsgid,
      playVoice,
      msgScroll,
      fileImgChange,
      fileChange,
      showDownFile,
      closeShowConsult,
      onKeyword,
      chatManage,
      moreHistory,
      isBrowseCurrentMsg,
      groupList,
      loading,
      showtime,
      groupListItem,
      chatroomItem,

      customerInfo,
      customerInfoForm,

      formRef,
      formTypeRef,
      labelCol: {span: 6},
      wrapperCol: {span: 18},
      other: "",
      formState,
      rules,
      onSubmit,
      onTypeSubmit,
      resetForm,

      formEditTipe,
      formEdit,
      formEditCancel,

      activeKey,
      askRemoveGroup,
      askQuitGroup,

      visibleSearch,
      serviceModal,
      serviceDataList,
      serviceActiveKey,
      serviceClick,
      faeItem,
      handleInvitationOk,

      serviceAddModal,
      visibleAddUserSearch,
      addUserVal,
      onUserItemChange,
      closeUser,
      handleUserOk,
      msgStateType,
      msgStateShow,
      seeMsgState,
      msgStateTime,
      visibleMsg,
      visibleMsgId,
      tapTransfer,

      servicName,
      onServicSearch,

      userSearch,
      getUser,

      page,

      visibleHistory,
      historyTitle,
      historyList,
      seeMsgRecord,
      getSendAsk,

      delAsk,

      dragTxt,
      dragShow,
      dropFileChange,

      handlePaste,
      pasteHasFile,
      textMsgChange,

      // audioRef,
      // openVoice,
      // playMsgAudio,

      msgImgLoad,

      timingFun,
      showChatroom,

      playBodyMsgAudio,
      onShow,

      resend,
      questionTypeValues,
      loadMoreAllGroup,
      groupAllLists,
      loadAllMore,
      showSelectRule,
      groupAllListItem,
      chooseOption,
      selectGroup,
      selectRobot,
      confirmJoinGroup,
      showNotifyUser,
      userStateInfo,
      unreadCount,
      GroupName,
      chatManageClean,
      getGroupMemberCount,
      chatBoxRef,
      chatBoxHeight,
      submitRemarkTxt,
      remarkTxt,
      getExistedConversationList,
      serviceDataCount,
      timestampToTime,
      sendUserEvaluation,
      isSendComment,
      commentLoading,
      customerCommentFlag,
      updateGroupUser,
      updateGroupState,
      getNotificationStatus,
      queueImgMsg,
      roles: [
        '工程师',
        '采购',
        '销售',
        '管理者',
        '学生',
      ]
    };
  },
  onUnmounted() {
    this.onShow = false;
  },
  watch: {
    textMsg(newVal, oldVal) {
      this.sendTypeChange();
      if (this.pasteHasFile) {
        this.textMsgChange(oldVal);
      }
    },
  },
  created() {
    document.addEventListener("click", (e: any) => {
      // emoji显示隐藏判断
      if (!this.emojiBodyShow) {
        return false;
      }
      let isShowEmoji = (this.$refs.showPanelBtn as any).contains(e.target);
      if (isShowEmoji) {
        return false;
      }
      if (this.$refs.showPanel) {
        //console.log(this.$refs.showPanel);
        //console.log(this.emojiBodyShow);
        let isSelf = (this.$refs.showPanel as any).contains(e.target);
        //console.log(isSelf);
        if (!isSelf && this.emojiBodyShow) {
          this.emojiBodyShow = false;
        }
      }
    });
  },
  mounted() {
    this.onShow = true;
  },
});
