// 节流(ts)
import {isReactive, isRef, toRefs} from "vue";

export class Throttle {
  private timer: number | undefined
  private stop = false
  private death = false
  /**
   * @param func 须要包装的函数
   * @param delay 延迟时间，单位ms
   * @param immediate 是否默认执行一次(第一次不延迟)
   */
  public use (func: Function, delay: number, immediate = false): Function {
    let flag = true
    const self = this
    return (...args: any) => {
      if (this.death) {
        func.apply(this, args)
        return
      }
      if (this.stop) {
        func.apply(this, args)
        return
      }
      if (immediate) {
        func.apply(this, args)
        immediate = false
        return
      }
      if (!flag) {
        return
      }
      flag = false
      self.timer = setTimeout(() => {
        func.apply(this, args)
        flag = true
      }, delay)
    }
  }

  // 销毁
  public destroy() {
    this.death = true
    this.stop = true
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = undefined
    }
  }
  // 开启
  public open() {
    if (!this.death) {
      this.stop = false
    }
  }
  // 关闭
  public close() {
    this.stop = true
  }
}

export class Debounced {
  /**
   * @param func 须要包装的函数
   * @param delay 延迟时间，单位ms
   * @param immediate 是否默认执行一次(第一次不延迟)
   */
  public use = (func: Function, delay: number, immediate = false): Function => {
    let timer: number | undefined
    return ( ...args: any) => {
      if (immediate) {
        func.apply(this, args) // 确保引用函数的指向正确，而且函数的参数也不变
        immediate = false
        return
      }
      clearTimeout(timer)
      timer = setTimeout(() => {
        func.apply(this, args)
      }, delay)
    }
  }
}


export const listenerLevelBrowser = () =>{
  const hiddenProperty = 'hidden' in document ? 'hidden' :
      'webkitHidden' in document ? 'webkitHidden' :
          'mozHidden' in document ? 'mozHidden' :
              null;
  if (hiddenProperty){
    const visibilityChangeEvent = hiddenProperty.replace(/hidden/i, 'visibilitychange');
    const onVisibilityChange = function () {
      // @ts-ignore
      if (!document[hiddenProperty]) {
        document.title = '回来';
      } else {
        document.title = '离开';
      }
    };
    document.addEventListener(visibilityChangeEvent, onVisibilityChange);
  }
}

export const countTime = (daly:number):string => {
  //定义变量 d,h,m,s保存倒计时的时间

  let d, h, m, s;
  if (daly >= 0) {
    daly = daly*1000;
    d = Math.floor(daly / 1000 / 60 / 60 / 24);
    h = Math.floor(daly / 1000 / 60 / 60 % 24);
    m = Math.floor(daly / 1000 / 60 % 60);
    m = m < 10 ? `0${m}` : m;
    s = Math.floor(daly / 1000 % 60);
    s = s < 10 ? `0${s}` : s;

  }

  return `${m}:${s}`
}

/**
 * 复制文本
 * @param text
 */
export const copyText = (text: string) => {
  return new Promise((resolve, reject) => {
    const copyInput = document.createElement('input') //创建一个input框获取需要复制的文本内容
    copyInput.value = text
    document.body.appendChild(copyInput)
    copyInput.select()
    document.execCommand('copy')
    copyInput.remove()
    resolve(true)
  })
}

export const arraySum = (arr:any[], field: string) => {
  // console.log(arr);
  if (!arr){
    return  0
  }
  let s = 0;
  for (let i = arr.length - 1; i >= 0; i -= 1) {
    s += arr[i][field];
  }
  return s;
};

export const sleep:(daly?: number) => Promise<boolean> = (daly = 0) => new Promise((resolve, reject) => {
  setTimeout(() => {
    resolve(true);
  }, daly);
});


export const minutesSeconds = (time: number) =>{
  let m = 0, s = 0
  if (time >= 60){
    m = Math.ceil(time / 60)
  }

  s = time - m*60
  return `${m}分钟` + (s?`${s}秒`:'')
}


/**
 * 关键词高亮
 * @param val
 * @param keyword
 */
export const brightenKeyword = (val: any, keyword: any) => {
  if (keyword.length > 0) {
    const keywordArr = [keyword.toLocaleUpperCase()];
    val += "";
    keywordArr.forEach((item: any) => {
      if (val.toLocaleUpperCase().indexOf(item) !== -1 && item !== "") {
        val = val.replace(
            new RegExp(item.toString(), "gi"),
            `<span style="color: #ef2e23">${item}</span>`,
        );
      }
    });
    return val;
  }
  return val;
};


const specials:any = {
  "U+002A":"*", "U+0026":"&", "U+005E":"^", "U+0025":"%", "U+0024":"$",
  "U+0023":"#", "U+0040":"@", "U+0021":"!",
  "U+007E":"~", "U+FF01":"！", "U+FFE5":"￥", "U+2026":"…", "U+FF08":"（", "U+FF09":"）", "U+0028":"(", "U+0029":")",
}
export const specialSymbol = (str: string) => {
  for (const s in specials){
    str = str.replaceAll(s, specials[s])
  }
  return str
}
