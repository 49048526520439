
import { defineComponent} from "vue";
import {Modal, Row, Spin} from "ant-design-vue";
import { useRoute, useRouter } from "vue-router";
import {API} from "@/assets/js/Api";

export default defineComponent({
  components: {
    [Spin.name]: Spin,
    [Row.name]: Row,

  },
  setup() {
    // console.log("API", API);
    const route = useRoute();
    const router = useRouter();
    // console.log("route", route.query);
    // 弹出层
    const confirm = () => {
      Modal.error({
        title: "温馨提示",
        content: "系统暂未检测到您到登录信息，请重新登录！",
        okType: "danger",
        okText: "确认",
        onOk() {
          window.location.href = `${process.env.VUE_APP_WEBSITE}/customerService_Login?logtype=1`;
        },
      });
    };
    const getUserPlatformInfo = (id: any) => {
      let data = {
        IsCustomerClient: 0,
        unionId: id,
        channelType: 1, //属于哪个渠道：1 pc；2 小程序
      };
      API.getUserPlatformInfo(data).then((res: any) => {
        // uni.hideLoading();
        console.log("登录获取平台用户信息：", res);
        let json = res.data;
        sessionStorage.setItem("userPlatformInfo", JSON.stringify(json));
        // this.userPlatformInfo = json;
        if (json.im_user_type == 1) {
          Modal.error({
            title: "温馨提示",
            content: "该账号不是客服！",
            okType: "danger",
            okText: "确认",
            onOk() {
              window.location.href = `${process.env.VUE_APP_WEBSITE}/customerService_Login?logtype=1`;
            },
          });
        } else {
          router.push("/conversation");
        }
      });
    };

    const uid = route.query.uid;
    if (uid) {
      localStorage.setItem("unionid", JSON.stringify(uid));
      getUserPlatformInfo(uid);
      router.push({ query: {} });
    } else {
      confirm();
    }



    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
    };
  },
});
