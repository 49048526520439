import '@babel/polyfill';
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import axios from 'axios';
import VueAxios from 'vue-axios';

import '@/assets/css/common.scss';
import '@/assets/css/style.scss'

import directives from '@/assets/js/time';


const orignalSetItem = window.localStorage.setItem;   // 原生localStorage.setItem方法
localStorage.setItem = function(key:any,newValue:any){
  const setItemEvent:any = new Event("setItemEvent");  // 重写注册setItem
  setItemEvent.key = key;
  setItemEvent.newValue = newValue;
  window.dispatchEvent(setItemEvent);            // 派发setItem
  // eslint-disable-next-line prefer-rest-params
  orignalSetItem.apply(this, (arguments as any));         // 设置值
}

const app = createApp(App);

// if (process.env.VUE_APP_NODE === "production"){
//   console.log = ()=>{}
// }
directives(app)

app
// .use(antd)
.use(store)
.use(router)
.use(VueAxios,axios)
.mount('#app')
