import { h, ref, defineComponent, reactive , watch} from 'vue'
import { useMouseInElement, MouseInElementOptions } from '@vueuse/core'

export interface RenderableComponent {
  as?: Object | string
}

 const UseMouseInElement = defineComponent<MouseInElementOptions & RenderableComponent>({
  name: 'UseMouseElement',
  props: ['handleOutside', 'as'] as unknown as undefined,
  setup(props, { slots }) {
    const target = ref()
    const data = reactive(useMouseInElement(target, props))
    //
    // watch(()=>data.isOutside, ()=>{
    // })
    return () => {
      // if (slots.default)
        // return h(props.as || 'div', { ref: target, title: 'Garmin' }, slots.default(data))
      return (
          <div ref={target}>
            {slots.default?.()}
        </div>
      )
    }
  },
})
export default UseMouseInElement
