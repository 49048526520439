
import {
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  WechatOutlined,
  TeamOutlined,
} from "@ant-design/icons-vue";
import {defineComponent, onMounted, ref, watch} from "vue";
import { useRoute, useRouter } from "vue-router";
import Emitter from "@/utils/mitt";
import {Badge, Layout, Menu} from "ant-design-vue";
import {API} from "@/assets/js/Api";
export default defineComponent({
  components: {
    WechatOutlined,
    TeamOutlined,
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined,
    [Layout.name]: Layout,
    [Layout.Sider.name]: Layout.Sider,
    [Layout.Content.name]: Layout.Content,
    [Menu.name]: Menu,
    [Menu.Item.name]: Menu.Item,
    [Badge.name]: Badge,
  },
  setup() {
    const router = useRoute();
    const selectedKeys = ref<any>([router.meta.navKey]);
    const onCollapse = (collapsed: boolean, type: string) => {
    };

    const onBreakpoint = (broken: boolean) => {
      // console.log(broken);
    };
    const unreadCount = ref<any>(0)

    Emitter.on('unreadCount', (val:number|unknown):void=>{
      if (typeof val === 'number'){
        unreadCount.value = val
      }
    })
    const companyList = ref([])
    const userPlatformInfo = ref<any>(JSON.parse(sessionStorage.getItem("userPlatformInfo") as string) || null);
    onMounted(async ()=>{
      if (router.query.mid){
        selectedKeys.value = [`chat_${router.query.mid}`]
      }
      const res:any = await API.manufacturerList({userId: ''+userPlatformInfo.value.userId})
      if (res && res.errno === 0){
        companyList.value = res.data
      }
    })

    return {
      companyList,
      selectedKeys,
      onCollapse,
      onBreakpoint,
      unreadCount
    };
  },
  watch: {
    //路由监控
    $route(val) {
      this.selectedKeys[0] = val.meta.navKey;
    },
  },
});
