import {computed, defineComponent, nextTick, ref} from "vue";
// @ts-ignore
import Cls from './index.module.scss'
import {brightenKeyword} from "@/utils";

export default defineComponent({
  name: 'MarkTxt',
  props:{
    txt:{
      type: String,
      default: ''
    },
    keyword:{
      type: String,
      default: ''
    },
    color:{
      type: String,
      default: '#D94232'
    },
    ellipsis:{
      type: Number,
      default: 0
    },
    prefix:{
      type: String,
      default: ''
    }
  },
  setup(props){
    const effect = ref('')
    const { ellipsis} = props
    const markTxt = computed<{
      before: string
      txt:string,
      after:string
    }>(()=>{
      let before = '';
      let content = '';
      let after = '';

      const pos = props.txt.toUpperCase().indexOf(props.keyword.toUpperCase());
      if (pos !== -1){
        before = props.txt.substring(0, pos)
        if (ellipsis > 0){
          before = before.substring(before.length - +ellipsis, before.length);
        }
        content = props.keyword
        after = props.txt.substring(pos+props.keyword.length, props.txt.length)
      }else{
        after = props.txt
      }
      nextTick(()=>{
        refdom.value!.innerHTML = brightenKeyword(props.prefix, props.keyword)+"："
      })

      return {
        before,
        txt:content,
        after
      }
    })
    const refdom = ref<HTMLDivElement>()

    return ()=>(<div class={Cls.keyword} title={props.txt}>
      <span style={{display:props.prefix ?'inline-block':'none'}} ref={refdom}/>
      { markTxt.value.before }
      <span style={{color: props.color}}>{ markTxt.value.txt }</span>
      { markTxt.value.after }
    </div>)
  }
})
