import axios from 'axios';
// import Vuex from 'vuex'
const instance = axios.create({
   baseURL: process.env.VUE_APP_INTERFACE_ADDR //测试环境请求域名
    // baseURL: 'http://ac.emakerzone.com/v2/1v1/customer/' //测试环境请求域名
    //  baseURL:'/api/customer'
  });
 
// 由于进入页面后无法获得存储的token，时间关系，先采用在各个方法内引用headers
// instance.defaults.headers.common['Authorization'] =encodeURIComponent('Bearer ' + sessionStorage.getItem('token'));

// 封装get方法
export function GET(url:string,params:any) {
    return new Promise((resolve,reject)=>{
        // console.log("获取token，get",sessionStorage.getItem('token'))
        //headers引入  
        instance.defaults.headers.common['token'] = encodeURIComponent( '' + sessionStorage.getItem('token'));//'Bearer ' + 
        instance.get(url,{params:params})
        .then((response)=> {
            resolve(response.data)
        })
        .catch((error)=>{
            reject(error.response.data);
        }); 
    })
}
//封装post方法
export function POST(url:string,...params:any){
    return new Promise((resolve,reject)=>{
        // console.log("获取token，post",sessionStorage.getItem('token'))
        //headers引入  
        instance.defaults.headers.common['Authorization'] =encodeURIComponent( '' + sessionStorage.getItem('token'));//Bearer ' + 
        instance.post(url, ...params)
        .then((response)=> {
            resolve(response.data)
        })
        .catch((error)=>{
            reject(error.response.data);
        });
    })
}

