import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  // {
  //   path:'/',
  //   name:'系统登录',
  //   component:() => import('../pages/login.vue'),
  //   meta: { keepAliveNav: false }
  // },
  {
    path:'/',
    name:'登录',
    component:() => import('../pages/login.vue'),
    meta: { keepAliveNav: false }
  },
  {
    path:'/conversation',
    name:'会话',
    component:() => import('../pages/conversation/index.vue'),
    meta: { keepAliveNav: true ,navKey:'all'}
  },
  {
    path:'/serviceList',
    name:'通讯录',
    component:() => import('../pages/service/serviceList.vue'),
    meta: { keepAliveNav: true ,navKey:'slist'}
  },
  {
    path:'/jump',
    name:'跳转',
    component:() => import('../pages/jump'),
    meta: { keepAliveNav: false }
  },


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
// 设置title
router.afterEach((to, from) => {
  (<any>window).document.title = to.name
})

export default router
