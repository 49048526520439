/* const emojiJson:Array<any> = [
    [
        { "url": "0.png", alt: "[微笑]" },
        { "url": "1.png", alt: "[撇嘴]" },
        { "url": "2.png", alt: "[色]" },
        { "url": "3.png", alt: "[发呆]" },
        { "url": "4.png", alt: "[得意]" },
        { "url": "5.png", alt: "[流泪]" },
        { "url": "6.png", alt: "[害羞]" },
        { "url": "7.png", alt: "[闭嘴]" },
        { "url": "8.png", alt: "[睡]" },
        { "url": "9.png", alt: "[大哭]" },
        { "url": "10.png", alt: "[尴尬]" },
        { "url": "11.png", alt: "[发怒]" },
        { "url": "12.png", alt: "[调皮]" },
        { "url": "13.png", alt: "[呲牙]" },
        { "url": "14.png", alt: "[惊讶]" },
        { "url": "15.png", alt: "[难过]" },
        { "url": "17.png", alt: "[冷汗]" },
        { "url": "18.png", alt: "[抓狂]" },
        { "url": "19.png", alt: "[吐]" },
        { "url": "20.png", alt: "[偷笑]" },
        { "url": "21.png", alt: "[愉快]" },
        { "url": "22.png", alt: "[白眼]" },
        { "url": "23.png", alt: "[傲慢]" },
        { "url": "25.png", alt: "[困]" }
    ],
    [
        { "url": "26.png", alt: "[惊恐]" },
        { "url": "27.png", alt: "[流汗]" },
        { "url": "28.png", alt: "[憨笑]" },
        { "url": "29.png", alt: "[悠闲]" },
        { "url": "30.png", alt: "[奋斗]" },
        { "url": "31.png", alt: "[咒骂]" },
        { "url": "32.png", alt: "[疑问]" },
        { "url": "33.png", alt: "[嘘]" },
        { "url": "34.png", alt: "[晕]" },
        { "url": "36.png", alt: "[衰]" },
        { "url": "37.png", alt: "[骷髅]" },
        { "url": "38.png", alt: "[敲打]" },
        { "url": "39.png", alt: "[再见]" },
        { "url": "40.png", alt: "[擦汗]" },
        { "url": "41.png", alt: "[抠鼻]" },
        { "url": "42.png", alt: "[鼓掌]" },
        { "url": "44.png", alt: "[坏笑]" },
        { "url": "45.png", alt: "[左哼哼]" },
        { "url": "46.png", alt: "[右哼哼]" },
        { "url": "47.png", alt: "[哈欠]" },
        { "url": "48.png", alt: "[鄙视]" },
        { "url": "49.png", alt: "[委屈]" },
        { "url": "50.png", alt: "[快哭了]" },
        { "url": "51.png", alt: "[阴险]" }
    ],
    [
        { "url": "52.png", alt: "[亲亲]" },
        { "url": "54.png", alt: "[可怜]" },
        { "url": "55.png", alt: "[菜刀]" },
        { "url": "56.png", alt: "[西瓜]" },
        { "url": "57.png", alt: "[啤酒]" },
        { "url": "60.png", alt: "[咖啡]" },
        { "url": "61.png", alt: "[饭]" },
        { "url": "62.png", alt: "[猪头]" },
        { "url": "63.png", alt: "[玫瑰]" },
        { "url": "64.png", alt: "[凋谢]" },
        { "url": "65.png", alt: "[嘴唇]" },
        { "url": "66.png", alt: "[爱心]" },
        { "url": "67.png", alt: "[心碎]" },
        { "url": "68.png", alt: "[蛋糕]" },
        { "url": "70.png", alt: "[炸弹]" },
        { "url": "74.png", alt: "[便便]" },
        { "url": "75.png", alt: "[月亮]" },
        { "url": "76.png", alt: "[太阳]" },
        { "url": "78.png", alt: "[拥抱]" },
        { "url": "79.png", alt: "[强]" },
        { "url": "80.png", alt: "[弱]" },
        { "url": "81.png", alt: "[握手]" },
        { "url": "82.png", alt: "[胜利]" },
        { "url": "83.png", alt: "[抱拳]" }
    ],
    [
        { "url": "84.png", alt: "[勾引]" },
        { "url": "85.png", alt: "[拳头]" },
        { "url": "89.png", alt: "[OK]" },
        { "url": "92.png", alt: "[跳跳]" },
        { "url": "93.png", alt: "[发抖]" },
        { "url": "94.png", alt: "[怄火]" },
        { "url": "95.png", alt: "[转圈]" },
        { "url": "300.png", alt: "[笑脸]" },
        { "url": "301.png", alt: "[生病]" },
        { "url": "302.png", alt: "[破涕为笑]" },
        { "url": "303.png", alt: "[吐舌]" },
        { "url": "304.png", alt: "[脸红]" },
        { "url": "305.png", alt: "[恐惧]" },
        { "url": "306.png", alt: "[失望]" },
        { "url": "307.png", alt: "[无语]" },
        { "url": "204.png", alt: "[嘿哈]" },
        { "url": "205.png", alt: "[捂脸]" },
        { "url": "202.png", alt: "[奸笑]" },
        { "url": "206.png", alt: "[机智]" },
        { "url": "212.png", alt: "[皱眉]" },
        { "url": "211.png", alt: "[耶]" },
        { "url": "313.png", alt: "[吃瓜]" },
        { "url": "314.png", alt: "[加油]" },
        { "url": "315.png", alt: "[汗]" }
    ],
    [
        { "url": "316.png", alt: "[天啊]" },
        { "url": "317.png", alt: "[Emm]" },
        { "url": "318.png", alt: "[社会社会]" },
        { "url": "319.png", alt: "[旺柴]" },
        { "url": "320.png", alt: "[好的]" },
        { "url": "321.png", alt: "[打脸]" },
        { "url": "322.png", alt: "[哇]" },
        { "url": "308.png", alt: "[鬼魂]" },
        { "url": "309.png", alt: "[合十]" },
        { "url": "214.png", alt: "[鸡]" },
        { "url": "310.png", alt: "[强壮]" },
        { "url": "311.png", alt: "[庆祝]" },
        { "url": "312.png", alt: "[礼物]" },
        { "url": "209.png", alt: "[红包]" }
    ]
]; */
const emojiJson:Array<any> = [
    
        { "url": "0.png", alt: "[微笑]" },
        { "url": "1.png", alt: "[撇嘴]" },
        { "url": "2.png", alt: "[色]" },
        { "url": "3.png", alt: "[发呆]" },
        { "url": "4.png", alt: "[得意]" },
        { "url": "5.png", alt: "[流泪]" },
        { "url": "6.png", alt: "[害羞]" },
        { "url": "7.png", alt: "[闭嘴]" },
        { "url": "8.png", alt: "[睡]" },
        { "url": "9.png", alt: "[大哭]" },
        { "url": "10.png", alt: "[尴尬]" },
        { "url": "11.png", alt: "[发怒]" },
        { "url": "12.png", alt: "[调皮]" },
        { "url": "13.png", alt: "[呲牙]" },
        { "url": "14.png", alt: "[惊讶]" },
        { "url": "15.png", alt: "[难过]" },
        { "url": "17.png", alt: "[冷汗]" },
        { "url": "18.png", alt: "[抓狂]" },
        { "url": "19.png", alt: "[吐]" },
        { "url": "20.png", alt: "[偷笑]" },
        { "url": "21.png", alt: "[愉快]" },
        { "url": "22.png", alt: "[白眼]" },
        { "url": "23.png", alt: "[傲慢]" },
        { "url": "25.png", alt: "[困]" },
        { "url": "26.png", alt: "[惊恐]" },
        { "url": "27.png", alt: "[流汗]" },
        { "url": "28.png", alt: "[憨笑]" },
        { "url": "29.png", alt: "[悠闲]" },
        { "url": "30.png", alt: "[奋斗]" },
        { "url": "31.png", alt: "[咒骂]" },
        { "url": "32.png", alt: "[疑问]" },
        { "url": "33.png", alt: "[嘘]" },
        { "url": "34.png", alt: "[晕]" },
        { "url": "36.png", alt: "[衰]" },
        { "url": "37.png", alt: "[骷髅]" },
        { "url": "38.png", alt: "[敲打]" },
        { "url": "39.png", alt: "[再见]" },
        { "url": "40.png", alt: "[擦汗]" },
        { "url": "41.png", alt: "[抠鼻]" },
        { "url": "42.png", alt: "[鼓掌]" },
        { "url": "44.png", alt: "[坏笑]" },
        { "url": "45.png", alt: "[左哼哼]" },
        { "url": "46.png", alt: "[右哼哼]" },
        { "url": "47.png", alt: "[哈欠]" },
        { "url": "48.png", alt: "[鄙视]" },
        { "url": "49.png", alt: "[委屈]" },
        { "url": "50.png", alt: "[快哭了]" },
        { "url": "51.png", alt: "[阴险]" },
        { "url": "52.png", alt: "[亲亲]" },
        { "url": "54.png", alt: "[可怜]" },
        { "url": "55.png", alt: "[菜刀]" },
        { "url": "56.png", alt: "[西瓜]" },
        { "url": "57.png", alt: "[啤酒]" },
        { "url": "60.png", alt: "[咖啡]" },
        { "url": "61.png", alt: "[饭]" },
        { "url": "62.png", alt: "[猪头]" },
        { "url": "63.png", alt: "[玫瑰]" },
        { "url": "64.png", alt: "[凋谢]" },
        { "url": "65.png", alt: "[嘴唇]" },
        { "url": "66.png", alt: "[爱心]" },
        { "url": "67.png", alt: "[心碎]" },
        { "url": "68.png", alt: "[蛋糕]" },
        { "url": "70.png", alt: "[炸弹]" },
        { "url": "74.png", alt: "[便便]" },
        { "url": "75.png", alt: "[月亮]" },
        { "url": "76.png", alt: "[太阳]" },
        { "url": "78.png", alt: "[拥抱]" },
        { "url": "79.png", alt: "[强]" },
        { "url": "80.png", alt: "[弱]" },
        { "url": "81.png", alt: "[握手]" },
        { "url": "82.png", alt: "[胜利]" },
        { "url": "83.png", alt: "[抱拳]" },

        { "url": "84.png", alt: "[勾引]" },
        { "url": "85.png", alt: "[拳头]" },
        { "url": "89.png", alt: "[OK]" },
        { "url": "92.png", alt: "[跳跳]" },
        { "url": "93.png", alt: "[发抖]" },
        { "url": "94.png", alt: "[怄火]" },
        { "url": "95.png", alt: "[转圈]" },
        { "url": "300.png", alt: "[笑脸]" },
        { "url": "301.png", alt: "[生病]" },
        { "url": "302.png", alt: "[破涕为笑]" },
        { "url": "303.png", alt: "[吐舌]" },
        { "url": "304.png", alt: "[脸红]" },
        { "url": "305.png", alt: "[恐惧]" },
        { "url": "306.png", alt: "[失望]" },
        { "url": "307.png", alt: "[无语]" },
        { "url": "204.png", alt: "[嘿哈]" },
        { "url": "205.png", alt: "[捂脸]" },
        { "url": "202.png", alt: "[奸笑]" },
        { "url": "206.png", alt: "[机智]" },
        { "url": "212.png", alt: "[皱眉]" },
        { "url": "211.png", alt: "[耶]" },
        { "url": "313.png", alt: "[吃瓜]" },
        { "url": "314.png", alt: "[加油]" },
        { "url": "315.png", alt: "[汗]" },
    
        { "url": "316.png", alt: "[天啊]" },
        { "url": "317.png", alt: "[Emm]" },
        { "url": "318.png", alt: "[社会社会]" },
        { "url": "319.png", alt: "[旺柴]" },
        { "url": "320.png", alt: "[好的]" },
        { "url": "321.png", alt: "[打脸]" },
        { "url": "322.png", alt: "[哇]" },
        { "url": "308.png", alt: "[鬼魂]" },
        { "url": "309.png", alt: "[合十]" },
        { "url": "214.png", alt: "[鸡]" },
        { "url": "310.png", alt: "[强壮]" },
        { "url": "311.png", alt: "[庆祝]" },
        { "url": "312.png", alt: "[礼物]" },
        { "url": "209.png", alt: "[红包]" },
    
];

export default emojiJson