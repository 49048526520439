import { createStore } from 'vuex'

export default createStore({
  state: {
    online:1,
    name: "test",
  },
  mutations: {
    changeOnline(state) {
      (state as any).online = state;
    },
    changeTest(state) {
      state.name = "sss";
    },
  },
  actions: {
  },
  modules: {
  }
})
