export default function useCss(cssStr:string) {
  const style:HTMLStyleElement = document.createElement("style");
  style.type = "text/css";
  try{
    style.appendChild(document.createTextNode(cssStr));
  }catch(ex){
    (style as any).styleSheet.cssText = cssStr;//针对IE
  }
  const head = document.getElementsByTagName("head")[0];
  head.appendChild(style);
}