
import { defineComponent, ref , provide} from "vue";
import layoutNav from "./components/nav.vue";
import layoutBody from "./components/body.vue";
import {Layout} from "ant-design-vue";
export default defineComponent({
  components: {
    layoutNav,
    layoutBody,
    [Layout.name]: Layout,
  },
  setup() {
    const showUnRead = ref<boolean>(true)
    provide<boolean>('showUnRead', showUnRead.value)

    return {};
  },
});
