export const getFormatTime=(time:any)=>{
    const date =
        typeof time === "number"
          ? new Date(time)
          : new Date((time || "").replace(/-/g, "/"));
        const diff = (new Date().getTime() - date.getTime()) / 1000;
        const dayDiff = Math.floor(diff / 86400);

        const isValidDate =
            Object.prototype.toString.call(date) === "[object Date]" &&
            !isNaN(date.getTime());

        if (!isValidDate) {
            window.console.error("不是有效日期格式");
        }
        return (
            (dayDiff < 1 &&
            ((diff < 60 && "刚刚") ||
                (diff < 120 && "1分钟前") ||
                (diff < 3600 && Math.floor(diff / 60) + "分钟前") ||
                (diff < 7200 && "1小时前") ||
                (diff < 86400 && Math.floor(diff / 3600) + "小时前"))) ||
            (dayDiff === 1 && "昨天") ||
            (dayDiff < 7 && dayDiff + "天前") ||
            (dayDiff <= 30 && Math.ceil(dayDiff / 7) + "周前") ||
            (dayDiff > 30 && Math.ceil(dayDiff / 30) + "月前") ||
            (dayDiff > 365 && Math.ceil(dayDiff / 365) + "年前")
        );
}
export default (app:any) => {
    //自定义组件
    app.directive('time', {
        /* // 指令绑定元素挂载前
  beforeMount(el) {},
  // 指令绑定元素挂载后
  mounted(el, binding) {},
  // 指令绑定元素因为数据修改触发修改前
  beforeUpdate(el) {},
  // 指令绑定元素因为数据修改触发修改后
  updated(el) {},
  // 指令绑定元素销毁前
  beforeUnmount(el) {},
  // 指令绑定元素销毁后
  unmounted(el) {}, */
  
        mounted(el:any, binding:any) {
            // console.log('mounted',el,binding)
            el.innerHTML = getFormatTime(binding.value);
            //每隔一分钟更新一次
            el.__timeout__ = setInterval(function(){
                el.innerHTML = getFormatTime(binding.value);
            }, 60000);
        },
        beforeUpdate(el:any, binding:any) {
            // console.log('beforeUpdate',el,binding)
            clearInterval(el.__timeout__);
            delete el.__timeout__;
        },
        updated(el:any, binding:any){
            // console.log('updated',el,binding)
            el.innerHTML = getFormatTime(binding.value);
            //每隔一分钟更新一次
            el.__timeout__ = setInterval(function(){
                el.innerHTML = getFormatTime(binding.value);
            }, 60000);
        },
        unmounted(el:any){
            clearInterval(el.__timeout__);
		    delete el.__timeout__;
        }
    })
}