import {defineComponent, onMounted, Ref, ref, unref, useCssModule, watch} from "vue";
import {
  ClockCircleOutlined,
  StopOutlined,
} from "@ant-design/icons-vue";
import UseMouseInElement from '@/hooks/UseMouseInElement/index'
import HoverMask from '@/components/hoverMask'
import {SearchOutlined, EllipsisOutlined} from '@ant-design/icons-vue'
import {timestampToTime} from '@/utils/dateformat'
import {Spin, Button, Avatar, Tag, List, Dropdown, Menu} from "ant-design-vue";

export default defineComponent({
  __cssModules: {
    index: require('./chatGroup.module.scss'),
  },
  components: {
    UseMouseInElement,
    SearchOutlined,
    EllipsisOutlined,
    HoverMask
  },
  props: {
    groupList: Array,
    chatroomItem: Object,
    isAll: {
      type: Boolean,
      default: false
    },
    pagination: {
      type: Boolean,
      default: false
    },
    loadMore: {
      type: Boolean,
      default: false
    },
    searchOpen: {
      type: Boolean,
      default: false
    },
    searchVal: {
      type: String,
      default: ''
    },
    chatroomId: {
      type: [Number, String],
      default: ''
    },
    unreadShow: {
      type: Boolean
    },
    scrollHeight: {
      type: Number,
      default: 700
    }
  },
  emits: ['clickItem', 'loadMore', 'update:searchVal', 'search', 'moreActions', 'openSearch'],
  setup(props, {emit}) {
    const userPlatformInfo = ref<any>(JSON.parse(sessionStorage.getItem("userPlatformInfo") as string) || null);

    const $style = useCssModule('index');

    // 点击进入聊天页面
    const groupListItem = (item: any): void => {
      console.log(111)
      emit('clickItem', item);
    }

    // 加载更多
    const loadingMore = ref<boolean>(false);

    const ChatItemView: object = {
      // 加载更多
      loadMore: () => {
        if (!props.pagination) {
          return;
        }
        if (!props.loadMore) {
          return <div class={$style['load-more']}>
            没有更多会话了...
          </div>
        }
        // 加载更多
        const handleLoadMore = () => {
          loadingMore.value = true
          emit('loadMore')
          setTimeout(() => {
            loadingMore.value = false
          }, 1000)
        }

        return (
            <div class={$style['load-more']}>
              {loadingMore.value ? <Spin/> : <Button onClick={handleLoadMore}>加载更多</Button>}
            </div>
        )
      },
      renderItem: ({item, index}: any) => {
        const {
          groupId,
          state,
          avatar,
          content_struct,
          chat_type,
          noReadNum,
          create_time,
          channelType,
          keyword,
          senderUserId = 'ss',
          is_new_group,
        } = item;
        const title = item.name + (item.chat_type !== 2 ? (item.second_name ? `【${item.second_name}】` : '') : '');
        const SlotView = {
          title: () => {
            return (
                <div class="flex jc-space-between w-100p">
                  <div class={[$style['group-title']]} title={title}>
                    {title}
                  </div>
                  <div class="font-14 col-b9 text-right fw-normal">
                    {timestampToTime(create_time)}
                  </div>
                </div>
            )
          },
          avatar: () => {
            let AvatarView;
            if (channelType == 1) {
              AvatarView = (
                  <Avatar
                      class={$style.avatars}
                      shape="square"
                      src="https://cloud-fae.oss-cn-shenzhen.aliyuncs.com/msgRemind/icon-shouye-web%402x.png"/>
              )
            } else {
              AvatarView = <Avatar class={$style.avatars} shape="square" src={avatar}/>
            }
            return (
                <span class="ant-badge">
                  <div style={{display: 'grid'}}>
                    {AvatarView}
                  </div>
                  {noReadNum > 0 ? (
                      <span class="ant-badge-count">{noReadNum > 99 ? "···" : noReadNum}</span>
                  ) : null}
                </span>
            )
          },
          description: () => {
            let Txtview;
            switch (content_struct?.object_name) {
              case "RC:RcCmd":
                Txtview = ''
                if (content_struct.extra) {
                  const extra = typeof content_struct.extra === 'string' ? JSON.parse(content_struct.extra) : content_struct.extra
                  const content = ((extra.userId === userPlatformInfo.value.userIdRC) ? '你' : extra.userName) + '撤回了一条消息';
                  Txtview = <span class="ellipsis-1">{content}</span>
                }
                break;
              case "RC:TxtMsg":
                Txtview = <span class="ellipsis-1">{content_struct.content}</span>
                break;
              case "RC:ImgMsg":
                Txtview = <span>[图片]</span>
                break;
              case "RC:HQVCMsg":
                Txtview = <span>[语音]</span>
                break;
              case "RC:FileMsg":
                Txtview = <span>[文件]</span>
                break;
              case "RC:InfoNtf":
                Txtview = <span>{content_struct.content}</span>
                break;
              case "RC:ImgMsg":
                Txtview = <span>{content_struct.content}</span>
                break;
            }

            let stateView
            if (chat_type === 1) {
              if (state === 1) {
                stateView = (
                    <div class="text-right extra-icon">
                      <ClockCircleOutlined style={{color: '#07C160'}}/>
                    </div>
                )
              } else {
                stateView = (
                    <div class="text-right extra-icon">
                      <StopOutlined style={{color: '#B9B9B9'}}/>
                    </div>
                )
              }
            }
            return (
                <div class={$style.txtBox}>
                  <div class="flex jc-space-between w-100p" style={{height: '21px'}}>
                    <div class={$style.txt}>
                      {Txtview}
                    </div>
                    <div class={$style.toBeProcessed}>
                      {senderUserId?.includes('c_')
                          ? <p>待处理</p> : null
                      }
                      {stateView}
                    </div>

                  </div>
                  <div class={'tag-co'} style={{ float:'left'}}>{keyword}</div>
                  {is_new_group ? (
                      <span class={$style['NewSpan']}>新用户</span>
                  ) : null}
                </div>
            )
          }
        }

        const lists = <div onClick={() => groupListItem(item)}><List.Item
            key={index}
            class={[$style['cu-item'], 'pad-15', groupId == props.chatroomId ? 'bg-f8' : '']}

        >
          <List.Item.Meta>
            {SlotView}
          </List.Item.Meta>
        </List.Item></div>
        if (props.isAll) {
          return lists
        }

        return (
            <HoverMask {...{'onClickMask': () => groupListItem(item)}}>
              {{
                default: () => lists,
                action: () => (
                    <div class='flex jc-space-between'>
                      <div class={['left', 'mt-25', 'ml-10', $style['mask-title']]}>
                        <Tag color="#2db7f5">{item.keyword}</Tag>
                        {title}
                      </div>
                      {
                        !item.look && (
                            <Dropdown getPopupContainer={(triggerNode: any) => triggerNode.parentNode}>
                              {{
                                default: () => (
                                    <div class={$style['more-menu']} onClick={(e) => {
                                      e.preventDefault()
                                      e.stopPropagation()
                                    }}>
                                      <EllipsisOutlined/>
                                    </div>
                                ),
                                overlay: () => (
                                    <div class={'bs'} onClick={(e: any) => {
                                      e.preventDefault()
                                      e.stopPropagation()
                                    }}>
                                      <Menu onClick={({items, key}: any) => {
                                        emit('moreActions', {
                                          type: key === 1 ? 'finish' : 'del',
                                          row: item
                                        })
                                      }}>
                                        <Menu.Item key={0}>
                                          <a href="javascript:;">删除会话</a>
                                        </Menu.Item>
                                        {
                                          item.memberType === 2 && item.chat_type === 1 ? (
                                              <Menu.Item key={1}>
                                                <a href="javascript:;">结束会话</a>
                                              </Menu.Item>
                                          ) : null
                                        }

                                      </Menu>
                                    </div>
                                )
                              }}
                            </Dropdown>
                        )
                      }
                    </div>
                )
              }}
            </HoverMask>
        )
      },
    }
    const loading = ref<boolean>(false);
    const navRef = ref()
    // 加载更多
    return () => (
        <div class="msg-nav" ref={navRef}>
          <div class={$style['search-box']} onClick={() => {
            emit('openSearch', props.isAll)
          }}>
            <SearchOutlined/> 搜索
          </div>
          <List
              item-layout="vertical"
              style={{'max-height': (props.scrollHeight - (props.searchOpen ? 105 : 110)) + 'px'}}
              size="large"
              class={[$style["vertical-list"], 'gm-position']}
              loading={loading.value}
              data-source={props.groupList}
          >
            {ChatItemView}
          </List>
        </div>
    );
  }
});
