import {defineComponent} from "vue";
// @ts-ignore
import Cls from './index.module.scss';

export default defineComponent({
  name: 'HoverMask',
  emits:['clickMask'],
  setup(props, {slots, emit}) {

    const handleClick = () => {
      emit('clickMask', '')
    }
    return () => (
        <div class={Cls['vue-hover-mask']}>
          {slots.default?.()}
          <div
            onClick={handleClick}
            class={Cls['vue-hover-mask_actions']}
          >
            {slots.action?.()}
          </div>
        </div>
    )
  }
})
