import {defineComponent, nextTick, onMounted, ref, unref} from "vue";
import {Input, message} from "ant-design-vue";
const rules = {
  email: {
    rule: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    tips: '邮箱格式不正确！'
  },
  mobile: {
    rule: /^(?:(?:\+|00)86)?1\d{10}$/,
    tips: '手机格式不正确！'
  },
}
export default defineComponent({
  name: 'EditTxt',
  props:{
    type: {
      default: 'text'
    },
    class: {
      default: ''
    },
    modelValue:{
      type: String,
      required: true,
    },
    isTextarea: {
      default: false
    },
    verify:{
      type: RegExp,
      default: ''
    },
    ruleTxt:{
      type: String,
      default: ''
    },
    disabled:{
      type: Boolean,
      default: false
    },
    txt: {
      type: Boolean,
      default: false
    }
  },
  emits:['update:modelValue', 'blur'],
  setup(props, {emit}){
    const showInput = ref<boolean>(false)
    const inputRef = ref<HTMLElement>()
    const initValue = ref<string>(props.modelValue)
    /**
     * 显示输入框
     * @param e
     */
    const handleShowInput = (e: MouseEvent) => {
      if (props.txt){
        return
      }
      showInput.value = true
      nextTick(()=>{
        inputRef.value!.focus()
      })
    }

    // 错误信息
    const errorTips = ref<string>('')

    /**
     * 规则验证
     * @param txt
     */
    const examine = (txt:string):boolean => {
      txt = txt.trim()
      // 判断规则是否正确
      if (props.verify){
        if (props.verify+'' === 'name'){
          if (txt.length >= 1 && txt.length <=12){
            errorTips.value = ''
            return true
          }else{
            errorTips.value = props.ruleTxt || '长度应为1-12个字符！'
            message.warning(errorTips.value)
            return false
          }
        }

        let rule, tips;
        if (props.verify instanceof RegExp){
          rule = props.verify
          tips = props.ruleTxt
        }else{
          rule = rules[props.verify]['rule']
          tips = props.ruleTxt || rules[props.verify]['tips']
        }

        if (!rule.test(txt)){
          errorTips.value = tips
          message.warning(errorTips.value)
          return false
        }else{
          errorTips.value = ''
          return true
        }
      }
      return true
    }
    /**
     * 失焦提交数据
     * @param e
     */
    const submitInputVal = (e: FocusEvent) => {
      const input = e.target as HTMLInputElement

      if(!examine(input.value)){
        emit('update:modelValue', unref(initValue))
        return false
      }else{
        showInput.value = false
        emit('blur', input.value)
      }
    }

    const handleOnChange = (e:any) => {
      emit('update:modelValue', e.target.value)
    }

    const tips = {
      color: 'red',
      fontSize: '13px',
      transform: 'scale(.8)'
    }

    return ()=>(
        <div class={props.class}>
          <div onClick={handleShowInput} style={{display: unref(showInput)? 'none': 'block'}}>{props.modelValue || <span style={{color: '#989696'}}>暂无</span>}</div>
          {props.isTextarea ?
              <Input.TextArea
                  disabled={props.disabled}
                  ref={inputRef}
                  defaultValue={props.modelValue}
                  onChange={handleOnChange}
                  onBlur={submitInputVal}
                  style={{display: !unref(showInput)? 'none': 'block'}}

              />
              :
              <Input
                  disabled={props.disabled}
                  ref={inputRef}
                  defaultValue={props.modelValue}
                  onChange={handleOnChange}
                  type={props.type}
                  onBlur={submitInputVal}
                  style={{display: !unref(showInput)? 'none': 'block'}}
              />
          }

          {/*<div style={{...tips, display: unref(errorTips) ? 'block': 'none'}}>{unref(errorTips)}</div>*/}

        </div>
    )
  }
})
