
import {
  LogoutOutlined,
  DownOutlined,
  MinusCircleFilled,
  CloseCircleFilled,
  CheckCircleFilled,
  ClockCircleFilled,
  SoundOutlined,

} from "@ant-design/icons-vue";
import {defineComponent, ref, provide} from "vue";
import { API } from "@/assets/js/Api";
import { useRequest } from "vue-request";
import {message, Modal, Layout, Button, Dropdown, Menu, Avatar} from "ant-design-vue";
import {useThrottleFn} from '@vueuse/core'
import {debounce} from "lodash-es";
const getMineType = (params: {}) => {
  return API.getMineType(params);
};
export default defineComponent({
  setup() {
    const userInfo = JSON.parse(sessionStorage.getItem("userPlatformInfo") as string);
    const mineInfo = ref<any>({});
    const logout = () => {
      sessionStorage.clear();
      window.location.href = `${process.env.VUE_APP_WEBSITE}/customerService_Login?logtype=1`;
    };
    const { run, data, loading } = useRequest(getMineType, {
      // manual: true,
      defaultParams: [
        {
          userId: userInfo.userId.toString(),
        },
      ],
      formatResult: (res: any) => res.data,
      onSuccess: (res: any) => {
        mineInfo.value = res;
      },
      throttleOptions:{
        leading: true,
        trailing: true,
      },
      throttleInterval: 5000
    });

    provide("sysSetting", data)

    const getMineTypeThrottle = useThrottleFn(() =>{
      return run({ userId: userInfo.userId.toString() });
    }, 1000)

    const handleOnline = (e: any) => {
      let data = {
        userId: userInfo.userId.toString(),
        is_online: e.key,
      };
      API.changeMineType(data).then((res: any) => {
        mineInfo.value.is_online = e.key
        if (res.errno == 0) {
          message.success("状态修改成功！");
          localStorage.setItem("online", JSON.stringify(e.key));
          getMineTypeThrottle()
        }
      });
    };
    window.addEventListener("setItemEvent", () => {
      getMineTypeThrottle()
    });

    const playAudio = (data: any) => {
      if (data) {
        playMsgAudio();
      }
    };

    // 播放提示音
    const audioRef = ref();
    const openVoice = ref<boolean>(true);
    const playModal = ref<boolean>(false);
    const playMsgAudio = () => {
      if (!openVoice.value) {
        return false;
      }
      // audioRef.value.play();
      (audioRef.value.play() as any)
        .then(() => {
        })
        .catch(() => {
          if (!playModal.value) {
            playModal.value = true;
            Modal.warning({
              title: "温馨提示",
              content: '您有新消息提醒！但语音播报失效,点击"激活"按钮，进行播报激活！',
              okText: "激活",
              okType: "danger",
              onOk() {
                playModal.value = false;
                audioRef.value.play();
              },
            });
          }
        });
    };

     const switchOnline = debounce(() => {
       if (mineInfo.value.is_online !== 1){
         handleOnline({key: 1})
       }
     }, 500, {leading: true})
    return {
      userInfo,
      logout,
      mineInfo,
      data,
      loading,
      handleOnline,

      playAudio,
      switchOnline,
      audioRef,
      openVoice,
      playMsgAudio,
    };
  },

  components: {
    LogoutOutlined,
    DownOutlined,
    MinusCircleFilled,
    CloseCircleFilled,
    CheckCircleFilled,
    ClockCircleFilled,
    SoundOutlined,
    [Layout.name]: Layout,
    [Layout.Content.name]: Layout.Content,
    [Layout.Header.name]: Layout.Header,
    [Button.name]: Button,
    [Dropdown.name]: Dropdown,
    [Menu.name]: Menu,
    [Menu.Item.name]: Menu.Item,
    [Avatar.name]: Avatar,
    [Layout.Content.name]: Layout.Content,
  },
});
