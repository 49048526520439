import {
  defineComponent, onMounted, onUnmounted, ref,watch
} from 'vue';
import Cls from './dragUpload.module.scss';
import { message } from 'ant-design-vue';
import emitter from '@/utils/mitt';

let timer: number | null | undefined = null;
export default defineComponent({
  name: 'DragUpload',
  props: {
    target: {
      type: String,
      default:'.area-upload',
      isRequire: true,
    },
    show: {
      type: Boolean,
      default: true,
    },
    chatroomId: {
      type: String,
      isRequire: true,
    },
  },
  emits: ['drag', 'send'],
  setup(props, { emit }) {
    const dragTxt = ref('');
    const dragShow = ref(false);
    let upload:HTMLDivElement|null = null;
    onMounted(() => {
      upload = document.querySelector(props.target) as HTMLDivElement;
      upload.addEventListener('dragenter', onDrag, false); // 进入
      upload.addEventListener('dragover', onDrag, false); // 移动
      upload.addEventListener('dragleave', onLeaveDrag, false); // 移开
      upload.addEventListener('drop', onDrop, false); // 松开上传
    });
    onUnmounted(() => {
      if (upload) {
        upload.removeEventListener('dragenter', onDrag);
        upload.removeEventListener('dragover', onDrag);
        upload.removeEventListener('dragleave', onLeaveDrag); // 移开
        upload.removeEventListener('drop', onDrop); // 松开上传
      }
    });

    watch(()=>props.chatroomId, ()=>{
      clearEmpty()
    })
    const onDrag = (e: any) => {
      // eslint-disable-next-line no-unused-expressions
      timer && clearTimeout(timer);
      emit('drag', true);
      dragTxt.value = '松开发送文件';
      dragShow.value = true;
      e.stopPropagation();
      e.preventDefault();
    };

    const onLeaveDrag = (e: any) => {
      console.log('onLeaveDrag ------------');
      timer = setTimeout(() => emit('drag', false), 500);
      dragTxt.value = '';
      dragShow.value = false;
      e.stopPropagation();
      e.preventDefault();
    };

    const files = ref<File[]>([]);
    const onDrop = (e: any) => {
      if (!props.chatroomId || !props.show)return
      emit('drag', false);
      dragTxt.value = '';
      // dragShow.value = false;
      e.stopPropagation();
      e.preventDefault();
      files.value = [...files.value, ...e.dataTransfer.files];
      console.log(e.dataTransfer.files);
      // dropFileChange(e);
    };

    const clearEmpty = () => {
      files.value = [];
      dragShow.value = false;
      emit('drag', false);
    };

    return () => (
      <>
        {dragShow.value && props.chatroomId && props.show && (
          <div class={Cls.mask}>
            <div class={Cls.tip}>{dragTxt.value}</div>
          </div>
        )}

        {files.value.length ? (
          <div class={Cls.fileBox}>
            <div class={Cls.header}>
              <div class={Cls.title}>发送文件</div>
              <div class={Cls.closeIcon} onClick={clearEmpty} />
            </div>
            <div class={Cls.body}>
              {
                files.value.map((file, i) => (
                  <div class={Cls.fileItem}>
                    <div class={Cls.cover} />
                    <div class={Cls.cont}>
                      <div class={Cls.fileName} title={file.name}>{file.name}</div>
                      <div class={Cls.fileSize}>{Math.floor(file.size / 1024)}KB</div>
                    </div>
                    <div class={[Cls.closeIcon, Cls.del]} onClick={() => {
                      files.value.splice(i, 1);
                      if (files.value.length <= 0) {
                        dragShow.value = false;
                        emit('drag', false);
                      }
                    }} />
                  </div>
                ))
              }
            </div>
            <div class={Cls.footer}>
              <div class={Cls.cancelBtn} onClick={clearEmpty}>取消</div>
              <div class={Cls.sendBtn} onClick={() => {
                emit('send', files.value)
                clearEmpty();
              }}>发送({files.value.length})</div>
            </div>
          </div>
        ) : null}

      </>
    );
  },
});
