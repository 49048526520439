import {defineComponent, reactive, ref, unref, watch} from "vue";
import {CloseOutlined, FileSearchOutlined, Loading3QuartersOutlined} from '@ant-design/icons-vue'
import {Drawer, Input, Button, List, Avatar, Empty, Popconfirm, message, Modal, Badge, Divider} from "ant-design-vue";
import Cls from './search.module.scss'
import {API} from "@/assets/js/Api";
import {ListItemMeta} from "ant-design-vue/es/list";
import MarkTxt from '@/components/mark-txt'
import {brightenKeyword} from "@/utils";
import {Spin} from "ant-design-vue/es";
const GroupStatus = {
  "current": 1,
  "end": 2,
  "all": 0,
}

export default defineComponent({
  title: 'Search',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: String,
      default: ''
    },
    joinGroup:{
      type: String,
      default: ''
    },
    isAll:{
      type: Boolean,
      default: true,
    },
    groupStatus:{
      type: String,
      default: 'current'
    }
  },
  emits: ['update:show', 'clickItem', 'close'],
  setup(props, {emit}) {
    const visible = ref(false)
    const loadm = ref(false)

    watch(() => props.show, () => {
      visible.value = props.show
      if (!visible.value){
        setParams(1,4,4,1)
        searchData.value = {concat: [], talk: []}
        searchVal.value = ''
        emit('close')
      }
    })
    watch(()=>props.joinGroup, ()=>{
      // 加入群聊
      searchData.value.talk.some(({groupId}, index)=>{
        if (`${groupId}` === `${props.joinGroup}`){
          (searchData.value.talk[index] as any).state = 1;
          (searchData.value.talk[index] as any).in_group = 1;
          return true
        }
        return false
      })
    })

    const searchData = ref({concat: [], talk:[]});
    const searchVal = ref<string>('')
    const isSearch = ref(false)
    const onSearch = () => {
      if (!searchVal.value.trim()){
        isSearch.value = false
        searchData.value = {concat: [], talk: []}
        setParams(1,4,4,1)
        return
      }
      searchGroupApi(1,4).then((data:any)=>{
        setParams(1,1,10,10)
        searchData.value = data
      })
    }

    const loading = ref({1: false, 2: false})
    const pageParam = reactive({
      concat: {
        pageNum: 1,
        pageSize: 4,
        total: 0
      }, talk: {
        pageNum: 1,
        pageSize: 4,
        total: 0
      }
    })
    const setParams = (cpageNum:number, tpageNum:number, cpageSize:number, tpageSize:number) => {
      pageParam.concat.pageNum = cpageNum
      pageParam.talk.pageNum = tpageNum;
      pageParam.concat.pageSize = cpageSize
      pageParam.talk.pageSize = tpageSize
    }
    /**
     * 搜索接口
     * @param pageNum
     * @param pageSize
     * @param type
     */
    const searchGroupApi = (pageNum=1, pageSize=10, type=0):Promise<any> => {
      if (type){
        loading.value = {...unref(loading), [type]: true}
      }
      loadm.value = true
      return new Promise(((resolve, reject) => {
        API.messageSearch({
          pageNum,
          pageSize,
          "search": searchVal.value,
          type,
          "userId": `${props.userId}`,
          is_all: props.isAll ? 1: 0,
          group_status: GroupStatus[props.groupStatus as "current"|"all"|"end"]
        }).then((res:any) => {
          if (res.errno === 0){
            pageParam.talk.total = res.data['talk_page'].total
            pageParam.concat.total = res.data['concat_page'].total
            return  resolve(res.data)
          }
          return reject('fail')
        }).finally(()=>{
          isSearch.value = true
          if (type){
            loading.value = {...unref(loading), [type]: false}
          }
          loadm.value = false
        }).catch(()=>{
          return reject('fail')
        })
      }))
    }

    /**
     * 点击群聊
     * @param item
     */
    const chatItem = (item:any, chat_type=1) => {
      if (item.groupId){
        emit('clickItem', {...item, second_name: item.nickname, memberType: item.member_type,chat_type})
      }else{
        // 创建会话
        Modal.confirm({
          title: () => '提示?',
          content: () => '确认创建对话',
          okText: '确认',
          cancelText: '取消',
          onOk() {
            addAlone(item)
          },
        });
      }
    }

    const addAlone = (item:any) =>{
      return new Promise((resolve, reject) => {
        const data = {
          userId: `${props.userId}`,
          otherUserId: `${item.uid}`
        };
        API.addUserAlone(data).then((res: any) => {
          const groupId = res.data.groupId
          if (res.errno == 0) {
            item.groupId = groupId;
            item.in_group = 1;
            item.state = 1;
            // this.toChatLink(groupId, 2,2, item.nickname)
            chatItem(item, 2)
          } else {
            message.error(res.errmsg)
          }
        });
      })
    };
    /**
     * 对话联系人列表
     * @param data
     * @param title
     */
    const listView = (data=[], type=1) => {
      const title = type === 1 ? '联系人' : '聊天记录'
      const pageName = type === 1 ? 'concat' : 'talk'
      const {pageNum, pageSize, total} = (pageParam as any)[pageName];

      /**
       * 加载更多
       */
      function onLoadMore(){
        searchGroupApi(pageNum, pageSize, type).then(data=>{
          if (type === 1){
            searchData.value.concat = data.concat
          }else{
            if (pageNum > 1){
              searchData.value.talk = searchData.value.talk.concat(data.talk)
            }else{
              searchData.value.talk = data.talk
            }
          }
          (pageParam as any)[pageName].pageNum = data[`${pageName}_page`].pageNum + 1;
          (pageParam as any)[pageName].pageSize = 10;
        })
      }

      return  <div class={'mb-15'}>
        <Badge class={'font-20 fw-500'} color="#108ee9" text={title} />
        <Divider style="border-color: #108ee9;margin: 8px 0;" dashed />
        <List item-layout="horizontal" data-source={data}>
        {{
          renderItem: ({item}:any) =>
          {
            return (
                <div onClick={()=>chatItem(item,type-1)}>
                <List.Item>
                  <ListItemMeta>
                    {{
                      description: ()=><MarkTxt prefix={item.talk_nickname} txt={item.content} ellipsis={6} keyword={unref(searchVal)} />,
                      title: () => <span><MarkTxt txt={item.nickname} ellipsis={10} keyword={unref(searchVal)} /></span>,
                      avatar: () => <Avatar src={item.avatar || 'https://cloud-fae.oss-cn-shenzhen.aliyuncs.com/msgRemind/icon-shouye-web%402x.png'} />
                    }}
                  </ListItemMeta>
                </List.Item>
                </div>
            )
          }
        }}
      </List>
        {total > 4 && ((pageNum-1) * pageSize) < total  &&  <Button loading={(loading.value as any)[type]} type="primary" size={'small'} class={'center-x mb-20 mt-20'} onClick={onLoadMore}>
            加载更多
        </Button>}
      </div>
    }

    return () => (
        <div class={'flex jc-space-between'}>
          <Drawer
              placement={'left'}
              destroyOnClose={true}
              visible={visible.value}
              onClose={() => {
                emit('update:show', false)
              }}
              width={300}
              mask={false}
              wrapStyle={{position: 'absolute', zIndex: 100}}
              getContainer={false}
              closable={false}
              title={<>
                <Input.Search
                    value={searchVal.value}
                    {...{'onUpdate:value': (value: string) => searchVal.value = value}}
                    placeholder="搜索"
                    enter-button
                    onSearch={onSearch}
                />
              </>}
          >
            <Spin  class="center-x mt-30" spinning={unref(loadm)} />
            <div class={Cls['group-box']}>
              {searchData.value.concat?.length ? listView(searchData.value.concat, 1) : null}
              {searchData.value.talk?.length ? listView(searchData.value.talk, 2): null}
              {isSearch.value && searchData.value.talk?.length <=0 && searchData.value.concat?.length<=0 && <Empty description={'暂无相关内容'} />}
              {!isSearch.value && <div style={{color: '#c5c5c5', fontSize:'20px'}}><FileSearchOutlined /> 搜索联系人和对话</div>}
            </div>
            <div class={Cls.close} onClick={() => emit('update:show', false)}>
              <CloseOutlined/>关闭
            </div>
          </Drawer>
        </div>
    )
  }
})
