
import { cloneDeep } from "lodash-es";
import { defineComponent, reactive, ref, UnwrapRef } from "vue";
import {API} from "@/assets/js/Api";
import { useRequest } from "vue-request";

import {Avatar, Button, Input, Layout, List, message, PageHeader, Table, Tag} from "ant-design-vue";
import { useRoute, useRouter } from "vue-router";
import {ListItemMeta} from "ant-design-vue/es/list";
const columns = [
  {
    title: "ID",
    dataIndex: "user_id",
    width: "15%",
    // slots: { customRender: 'name' },
    //  key: 'userId',
  },
  {
    title: "姓名",
    dataIndex: "real_name",

    slots: { customRender: "name" },
  },
  {
    title: "所在技能组",
    dataIndex: "group_name",
  },
  {
    title: "状态",
    dataIndex: "is_online",
    slots: { customRender: "isOnline" },
  },

  {
    title: "操作",
    dataIndex: "operation",
    slots: { customRender: "operation" },
    width: "30%",
  },
];

const getMailList = (params: {}) => {
  return API.getMailList(params);
};
export default defineComponent({
  components:{
    [PageHeader.name]: PageHeader,
    [Layout.name]: Layout,
    [Layout.Sider.name]: Layout.Sider,
    [Input.Search.name]: Input.Search,
    [List.name]: List,
    [List.Item.name]: List.Item,
    'a-list-item-meta': List.Item.Meta,
    [Tag.name]: Tag,
    [Table.name]: Table,
    [Avatar.name]: Avatar,
    [Button.name]: Button,
    [ListItemMeta.name]: ListItemMeta,
  },
  setup() {
    const router = useRouter();
    const userPlatformInfo = JSON.parse(
      sessionStorage.getItem("userPlatformInfo") as string
    );
    const dataSourceItem = ref<any>([]);
    const { run: runGroupList, data: dataSource, loading } = useRequest(getMailList, {
      // manual: true,
      defaultParams: [
        {
          // search: userSearch.value,
          userId: userPlatformInfo.userId.toString(),
        },
      ],
      formatResult: (res: any) => res.data.list,
      onSuccess: (res: any) => {
        dataSourceItem.value = res[0];
      },
    });
    const changeGroup = (item: any) => {
      dataSourceItem.value = item;
    };
    const userSearch = ref<string>("");
    const getUser = (type: number) => {
      runGroupList({
        search: userSearch.value,
        userId: userPlatformInfo.userId.toString(),
      });
    };
    // 查询单聊
    const getUserChat = (e: any) => {
      let data = {
        // groupId:e,
        userId: userPlatformInfo.userId.toString(),
        otherUserId: e.user_id.toString(),
      };

      API.queryUserAlone(data).then((res: any) => {

        if (res.data.groupId) {
          // 获取聊天记录

          router.push({ path: "/conversation", query: { gid: res.data.groupId } });
        } else {
          addAlone(e);
        }
      });
    };
    // 创建单聊
    const addAlone = (e: any) => {
      let data = {
        userId: userPlatformInfo.userId.toString(),
        otherUserId: e.user_id.toString(),
      };

      API.addUserAlone(data).then((res: any) => {
        if (res.errno == 0) {
          router.push({ path: "/conversation", query: { gid: res.data.groupId } });
        } else {
          message.error(res.errmsg);
        }
      });
    };
    return {
      dataSource,
      dataSourceItem,
      loading,
      columns,
      changeGroup,
      userSearch,
      getUser,

      getUserChat,
    };
  },
});
