const startTime = (time:number) => {
  const nowTimeDate = new Date(time)
  return nowTimeDate.setHours(0, 0, 0, 0)
}

export const timestampToTime = (timestamp:number, getDate=false, format='character') => {
  const date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
  const Y = date.getFullYear() ;
  const M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) ;
  const D = date.getDate();
  let h:any = date.getHours() ;
  let m:any = date.getMinutes();
  let s:any = date.getSeconds();
  s = s <=9 ? `0${s}`: s;
  m = m <=9 ? `0${m}`: m;
  h = h <=9 ? `0${h}`: h;
  if (getDate){
    return `${Y}/${M}/${D}`;
  }

  // console.log(Y , M , D , h , m , s);
  const day = new Date();
  const tomorrow = startTime(day.setTime(timestamp + 24*60*60*1000));
  const cYear = new Date().getFullYear();
  if (format === 'character' || format === 'allcharacter'){
    if (Date.now() < tomorrow){
      if (+h > 12){
        return `下午 ${+h-12}:${m}`;
      }
      if (+h > 18){
        return `晚上 ${+h-12}:${m}`;
      }

      if (+h > 6){
        return `上午 ${h}:${m}`;
      }
      return `凌晨 ${h}:${m}`;
    }
    if (format === 'allcharacter'){
      if (cYear <= Y) {
        return `${M}-${D} ${h}:${m}`;
      }
      return `${Y}-${M}-${D} ${h}:${m}`;
    }
  }else if(format === 'digital'){
    return `${h}:${m}`;
  }
  if (cYear <= Y) {
    return `${M}-${D}`;
  }
  return `${Y}/${M}/${D}`;
}
