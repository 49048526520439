import {nextTick, ref, Ref, unref, watch} from "vue";
export default function useDeleteEmoji(source:Ref, singleText: Ref){

  const newTxt = ref(unref(source));
  const cursorPos = ref(-1);

  // 监听文本变化
  watch(source, (newVal, oldVal)=>{
    // 没有文本的时候清空关闭位置
    if (newVal=== ''){
      cursorPos.value = -1;
    }
    // 文本删除操作，
    if (newVal.length < oldVal.length){
      // 最后文本是表情，
      if (oldVal.replace(newVal, '') === "]"){
        const matchMap = isExistEmoji(oldVal)
        if (matchMap.size){
          deleteEmojiCore(oldVal)
        }
      return
      }

      // 获取光标位置的字符
      // const originStr = oldVal.substring(0, getPosition())
      // const beforeStr = oldVal.substring(0, getPosition()-1)
      // if (originStr.replace(beforeStr, '') === "]"){
      //   // deleteEmojiCore(oldVal)
      // }
    }

    nextTick(()=>{
      getCursorPosition()
    })
  })

  // 点击事件获取光标位置
  const getCursorPosition = () =>{
    const element = singleText.value
    let cursorIndex = 0;
    if ((document as any).selection) {
      // IE Support
      element.focus();
      const range = (document as any).selection.createRange();
      range.moveStart('character', -element.value.length);
      cursorIndex = range.text.length;
    } else if (element.selectionStart || element.selectionStart==0) {
      // another support
      cursorIndex = element.selectionStart;
    }
    cursorPos.value = cursorIndex
  }

  // 设置光标位置
  const setCaretPosition = (newPos= -1) =>{
    const ctrl = unref(singleText);
    const pos = newPos >= 0 ? newPos : unref(cursorPos);
    if(ctrl.setSelectionRange) {
      ctrl.focus();
      nextTick(()=>{
        ctrl.setSelectionRange(pos,pos);
      })
    } else if (ctrl.createTextRange) {
      const range = ctrl.createTextRange();
      range.collapse(true);
      range.moveEnd('character', pos);
      range.moveStart('character', pos);
      range.select();
    }
  }

  const getPosition = () => {
    const pos = unref(cursorPos);
    return  pos >= 0 ? pos : unref(cursorPos)+1;
  }
  // 添加表情
  const addEmoji = (em: any) => {
    const emotionName = em.alt;
    const comment = unref(source)
    const pos = unref(cursorPos)
    const newComment =
        comment.slice(0, pos) +
        emotionName +
        comment.slice(pos);
    source.value = newComment;
    // 标签要变化
    cursorPos.value = getPosition() + emotionName.length
    setCaretPosition()
  };

  // 是否存在表情
  const isExistEmoji = (comment:string)  => {
    const matchMap = new Map();
    // 匹配所有的表情 找到光标最近的表情删除
    const matchArr:any = comment.matchAll(/\[([\u4e00-\u9fa5\w]+)\]/g);
    Array.from(matchArr).forEach((item:any)=>{
      const emj = item[0].length
      const poss = item['index']
      matchMap.set(emj + poss, emj)
    })

    return matchMap
  }
  // 删除表情
  const deleteEmojiCore = (msgText =''):void =>{
    const comment = msgText || unref(source)
    const pos = unref(cursorPos) < 0 ? comment.length : unref(cursorPos)

    if (cursorPos.value !== 0 && comment){
      const matchMap = isExistEmoji(comment)
      if (matchMap.size){
        // 判断光标前是否有表情
        if (matchMap.has(unref(cursorPos))){
          // 截取
          const strLen = matchMap.get(unref(cursorPos));
          const resStr = delStr(comment, strLen)
          // 改变光标位置
          cursorPos.value = pos - strLen;
          source.value = resStr;
          setCaretPosition()
          return
        }
      }
      const resStr = delStr(comment, 1)
      cursorPos.value = pos - 1;
      source.value = resStr;
      setCaretPosition()
    }
  }

  // 删除指定位置长度的字符
  const delStr = (comment:string, strLen:number) => {
    return comment.substring(0, unref(cursorPos) - strLen) +  comment.substring(unref(cursorPos), comment.length)
  }

  return {
    txt: unref(newTxt),
    getCursorPosition,
    setCaretPosition,
    deleteEmojiCore,
    addEmoji
  }
}

