export default class Store {
  private storage: any;

  constructor(storage:string) {
    this.storage = storage;
  }

  /**
   * 设置缓存
   * @param key
   * @param value
   * @param time 分钟
   */
  setItem(key:string, value:any, time:number) {
    time = time ? Date.now() + time * 1000 * 60 : 0;
    const params = {
      value,
      time: time || null,
    };
    (window[this.storage] as any).setItem(key, JSON.stringify(params));
  }

  getItem(key:string) {
    let params = (window[this.storage] as any).getItem(key);
    if (!params) return null;
    params = JSON.parse(params);
    if (params.time) {
      if (params.time > Date.now()) {
        return params.value;
      }
      this.removeItem(key);
      return null;
    }
    return params.value;
  }

  removeItem(key:string) {
    (window[this.storage] as any).removeItem(key);
  }

  clear() {
    (window[this.storage] as any).clear();
  }
}
