import {computed, defineComponent, inject, ref, watch} from "vue";
// @ts-ignore
import Cls from './index.module.scss'
import {copyText} from "@/utils/";
import {message, Modal} from "ant-design-vue";
import {API} from "@/assets/js/Api";



export default defineComponent({
  name: 'Tooltip',
  props:{
    visible: {
      type: Boolean,
      default:false
    },
    message:{
      type: Object,
      default: ()=>null
    },
    style:{
      type: Object,
    },
    user:{
      type: Object,
      isRequired: true
    }
  },
  emits:['withdrawal'],
  setup(props, {emit}){
    const {message: {content, targetId, messageUId, sentTime, messageType}} = props as any;
    // 复制
    const handleCopy = () => {
      copyText(content.content).then(r => {
        console.log(r);
        message.success('复制成功！')
      })
    }

    // 撤回消息
    const handleWithdrawal = () => {

      Modal.confirm({
        title: '提示',
        content: '是否撤回该条消息？',
        okText: '确认',
        cancelText: '取消',
        onOk() {
          const {userIdRC, nickname} = props.user as any
          API.recallMsg({msgUid:props.message.messageUId, extra: JSON.stringify({
              message: messageType == 'RC:TxtMsg' ? content.content: '',
              messageUId:props.message.messageUId,
              userId: userIdRC,
              userName: nickname
            })}).then(res=>{
            console.log(res, messageUId);
            emit('withdrawal', props.message.messageUId)
          }).catch(err=>{
            console.log(err);
          })
        }
      });
    }


    const menuItems = ref();
    watch(()=>props.visible, ()=>{
      menuItemFn()
    })
    const menuItemFn =() => {
      const menuItem = []

      // if (props.message.senderUserId.includes('robot_')){
      //   menuItems.value= []
      //   return
      // }
      // 判断是否为可以复制的类型
      if (messageType == 'RC:TxtMsg'){
        menuItem.push( <span class={Cls['item']} onClick={()=>handleCopy()}>复制</span>)
      }

      // 判断时间是否2分钟内
      if (Date.now() - props.message.sentTime < 60*2*1000 && props.message.messageDirection === 1){
        if (menuItem.length > 0){
          menuItem.push(<div class={Cls['line']} ></div>)
        }
        menuItem.push( <span class={Cls['item']} onClick={()=>handleWithdrawal()}>撤回</span>)
      }

      menuItems.value= menuItem || []
    }


    return ()=>(
        <>
        {
          props.visible && menuItems.value.length ?
              (<div class={[Cls['tooltip'], Cls['tooltip-placement-top'], Cls['gm-tooltip']]} style={props.style}>
            <div class={Cls['tooltip-content']}>
                <div class={Cls['tooltip-arrow']}>
                    <span class={Cls['tooltip-arrow-content']}></span>
                </div>
              <div class={Cls['opacity']}></div>
              <div class={Cls['tooltip-inner']} role="tooltip">
                {menuItems.value}
                </div>
            </div>
        </div>)
              : null
        }
        </>
    )
  }
})
