import {defineComponent, onMounted} from "vue";
import {message} from "ant-design-vue";
import {useRoute, useRouter} from "vue-router";
import {API} from "../assets/js/Api";
export default defineComponent({
    components: {},
    setup() {
        const router = useRouter();
        const route = useRoute();
        const reward_id = route.query.reward_id || 0;
        onMounted(()=>{
            judgmentJump();
        })
        const judgmentJump = ():void => {
            if (isWeiXin()){ // 微信
                // TODO 小程序跳转地址
                const params = {
                    path: 'pages/chats/issues/problem',
                    query: `?id=${reward_id}`,
                }
                API.generateUrlLink(params).then((res:any)=>{
                    if (res.errno === 0){
                        // 跳转小程序链接
                        window.location.href= res.data.value
                    }else{
                        throw res.errmsg;
                    }
                }).catch(error=>{
                    message.error(error)
                })
            }else{
                // 判断是否已经登录
                const unionid = localStorage.getItem('unionid');
                if (unionid){ // 已经登录的
                    router.push({path: 'conversation', query: {reward_id}}).then(() =>{})
                 }else{
                    window.location.href= `https://www.emakerzone.com/EZS/CYZservicelog.html?reward_id=${reward_id}`;
                }
            }
        }
        /**
         * 是否在微信浏览器中打开
         */
        const isWeiXin = (): boolean =>  {
            const ua = window.navigator.userAgent.toLowerCase();
            const match = ua.match(/MicroMessenger/i);
            if (match === null) {
                return false;
            }
            return !!match.includes('micromessenger');
        }
        return {
            labelCol: {span: 4},
            wrapperCol: {span: 14},
        };
    },
});
