import {defineComponent, nextTick, onMounted, ref, watch, unref, reactive} from "vue";
import $style from './share.module.scss'
import useCss from "@/hooks/useCss";
import {CloseOutlined} from '@ant-design/icons-vue'
import {Button, Card, Col, Form, Input, message, Modal, Row, Spin, Switch, Table} from "ant-design-vue";
import {API} from "@/assets/js/Api";
import {copyText, countTime} from "@/utils";
import {trim} from "lodash-es";

interface SettingFormIF{
  user_num:number,
  desc: string,
  is_see: number,
  talk_record: string,
  user_id: number,
  group_id: string,
  bounty: number
}
export default defineComponent({
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    uid: {
      type: Number,
      default: 0,
      isRequired: true
    },
    gid: {
      type: String,
      default: 0,
      isRequired: true
    }
  },
  emits:['update:open'],
  setup(props, {emit}){

    onMounted(()=>{
    })

    watch(()=>props.open, (val)=>{
      if (val){
        fetchRewardList();
      }
    })

    function copyLink(){
      if (!rewardLink.value.link){
        message.warning('请先设置分享')
        // return
      }

      copyText(rewardLink.value.link ).then(()=>{
        message.success('复制链接成功！')
      }).catch(()=>{
        message.error('复制链接失败！')
      })

    }
    const leftView = () =>{

      return (
          <>
            <div class={$style['left-qrcode']}>
              <div class={$style['qrcode']}>
                <div
                    class={$style['loading-share']}
                    v-show={rewardLink.value.loading}
                >
                  <Spin size="large" />
                </div>
                {rewardLink.value.url ? <img src={rewardLink.value.url} alt="小程序分享二维码"/> : <div class={$style['empty-share']}>请先设置分享</div>}
              </div>
              {/*<div class={$style['qrcode-tip']}>扫码小程序加入</div>*/}
            </div>
            <div class={$style['share-link']}>
              <input disabled={true} value={rewardLink.value.link} class={$style['link']} type="text"/>
              <div class={$style['copy']} onClick={copyLink}>复制</div>
            </div>
          </>
      )
    }
    const changeOpenModal = (value:boolean):void => {
      emit('update:open', value)
    }
    // 覆盖vue-ant样式
    nextTick(() => {
      useCss(".share-box .ant-modal-body, .share-box .ant-card-body{padding:0 !important;}.share-box .ant-table-body{height: 377px;overflow-y: scroll;}")
    }).then(()  =>{})

    // 生成分享链接和图片
    function fetchRewardSpread(){
      rewardLink.value.loading = true
      API.fetchWxQrcode({
        appid: 'wx28508f097ba78c1e',
        name: 'share'+Date.now(),
        secret: 'd7194c89af1d83fd8c838f807f944afc',
        path: `/pages/chats/issues/problem?id=${reward.value}`,
      }).then(({data, errno}:any)=>{
        if (errno === 0){
          rewardLink.value = {
            url: data.pic,
            link: `http://1v1pc.emakerzone.com/jump?reward_id=${reward.value}`,
            loading: false
          }
        }
      })
    }
    const activeKey = ref<string>('setting')
    const rewardLink = ref({url: '', link: '', loading: false})
    const reward = ref(0)
    // 分享设置
    const SettingView = () =>{
      const rules = {
        user_num: [
          {required: true, validator: (rule: any, value: number) => {
              if (+value <= 0) {
                return Promise.reject('请输入可进入人数');
              }
              if (+value > 999) {
                return Promise.reject('可进入人数不能超过999人');
              }
              return Promise.resolve('ok');
            },  trigger: 'blur' },
        ],
        bounty: [{validator: (rule: any, value: number, callback: any) => {
            if (+value < 0 || +value > 10000) {
              callback('请输入0-10000的数字')
              return
            }
            return Promise.resolve('ok');
          },required: true,  trigger: 'blur' }],
        desc: [{required: true, validator: (rule: any, value: string) => {
            if (!trim(value)) {
              return Promise.reject('请输入问题描述');
            }
            return Promise.resolve('ok');
          },  trigger: 'blur' },
          { min: 1, max: 200, required: true, message: '请输入200内的字符', trigger: 'blur'}],
      };
      const defaultState:SettingFormIF = {
        user_id: +props.uid, //
        group_id: props.gid+'',
        bounty: 0,
        user_num:1,
        desc: '',
        is_see: 1,
        talk_record: '',
      }
      const formState = reactive<SettingFormIF>(defaultState);
      const loading = ref<boolean>(false)
      const formRef = ref();
      // modelValue()

      // 生成链接
      async function generateLink() {
        loading.value = true;
        await formRef.value.validate().catch(():any=>{
          message.error('请正确填写表单内容！')
          loading.value = false;
          return Promise.reject('fail')
        });

        const {user_id,group_id,bounty,user_num,desc,is_see,talk_record} = formState;
        await API.createReward({
          user_id,
          group_id,
          desc,
          talk_record,
          bounty: +bounty,
          user_num: +user_num,
          is_see: +is_see
        }).then(({errno, data}:any)=>{
          if (errno === 0){
            reward.value = data.id;
            message.success('生成分享链接成功！ 可以复制链接或扫描二维码进入小程序');
            formState.desc = '';
            formState.user_num = 0;
            formState.bounty = 0;
            fetchRewardList();
            fetchRewardSpread();
            return;
          }

        }).catch(()=>{
          message.warning('生成分享链接失败！');
        }).finally(()=>{
          loading.value = false;
        })
      }
      return  (
          <Form class={$style['setting-box']} rules={rules}  ref={formRef} model={formState} labelCol={{ span:7 }} labelAlign={'left'}>
            <Form.Item label="可进入人数" name={'user_num'}>
              <Input type={'number'} maxlength={3} value={formState.user_num} {...{'onUpdate:value': (val:any)=>formState.user_num= val}} />
            </Form.Item>
            <Form.Item label="悬赏积分" name={'bounty'}>
               <Input
                   class={$style['bounty']}
                   type={'number'}
                   maxlength={4}
                   value={formState.bounty}
                   {...{'onUpdate:value': (val:any)=>formState.bounty= val>10000 ? 10000 : val}}
               />
               <div class={$style['convert']}>=金额{formState.bounty/10}元</div>
            </Form.Item>
            <Form.Item label="允许查看群成员" name={'is_see'}>
              <Switch checked={formState.is_see === 1} {...{'onUpdate:checked': (val:any)=>formState.is_see= val ? 1: 0}}  />
            </Form.Item>
            <Form.Item label="问题描述" wrapperCol={{span: 24}} >
              <Input.TextArea
                  placeholder="请输入200个字内的描述"
                  maxlength={200}
                  style={{height: '100px'}}
                  value={formState.desc}
                  {...{'onUpdate:value': (val:any)=>formState.desc= val}}
              />
            </Form.Item>

            <div class={$style['fl-c']}>
              <Button loading={loading.value} onClick={()=>generateLink()}>生成链接</Button>
            </div>
          </Form>
      )
    }

    const shareList = ref<any []>([]);
    function fetchRewardList(){
      API.fetchRewardList({
        group_id: props.gid+'',
        user_id: props.uid
      }).then(response=>{
        const {errno, data} = response as any
        if (errno === 0){
          shareList.value = data
          nextTick(() => {
            shareList.value.forEach((item) => {
              countdownFn(item);
            }, 1000)
          }).then( () =>{})
        }
      })
    }
    function countdownFn(item:any):void{
      let time = item.countdown;
      const timer = setInterval(()=>{
        time--;
        let txt: string;
        if (time > 0){
          const countdown = countTime(+time);
          txt =  `${countdown} 后失效`
        }else{
          clearInterval(timer);
          txt =  `链接失效`
        }
        item[`time_txt`] = txt
      }, 1000)
    }
    // 分享列表
    const ListView = () =>{
      const columns = [
        {
          title: '分享时间',
          dataIndex: 'created',
          customRender: (scope:any) => {
            return scope.text.substr(0, 10)
          },
        },
        {
          title: '限制人数',
          dataIndex: 'user_num',
        },
        {
          title: '进入',
          dataIndex: 'in_user_num',
        },
        {
          title: '状态',
          dataIndex: 'time_txt',
          width: 150
        },
      ];

      return <div class={$style['share-list']}>
        <Table columns={columns} class={[$style['table-box']]} data-source={shareList.value} bordered/>
      </div>
    }

    return ()=>(
        <Modal
            width={'800px'}
            style={{'height': '600px'}}
            closable={false}
            footer={null}
            title={null}
            visible={props.open}
            onCancel={()=>changeOpenModal(false)}
            onOk={()=>changeOpenModal(true)}
            class={['share-box', $style['share-box']]}
        >
          <Row>
            <Col span={10} class={$style['share-left']}>
              {leftView()}
            </Col>
            <Col span={14} class={$style['share-right']}>
              <Card>
              {{
                title: () => {
                  const activeProps:any = {
                    setting: {
                      class: [$style['item'], activeKey.value=== 'setting' && $style['active']],
                      onClick: ()=> activeKey.value = 'setting'
                    },
                    list: {
                      class: [$style['item'], activeKey.value=== 'list' && $style['active']],
                      onClick: ()=> activeKey.value = 'list'
                    }
                  }
                  return <div class={$style['tabs']}>
                    <div {...activeProps.setting} >分享设置</div>
                    <div {...activeProps.list} >分享列表</div>
                  </div>
                },
                extra: ()=><CloseOutlined onClick={()=>emit('update:open', false)} />,
                default: unref(activeKey) === 'setting' ? SettingView : ListView
              }}
              </Card>
            </Col>
          </Row>
        </Modal>
    )
  }
})
